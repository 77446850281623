import createSelector from "../utils/createSelector";
import applyLayout from "../utils/apply-relative-layout";

import alignLeafLabelsSelector from "./alignLeafLabels";
import parsedTreeSelector from "./parsedTree";
import treeTypeSelector from "./treeType";
import showLeafLabelsSelector from "./showLeafLabels";

export default createSelector(
  parsedTreeSelector,
  treeTypeSelector,
  (tree) => alignLeafLabelsSelector(tree) && showLeafLabelsSelector(tree),
  (
    nodes,
    type,
    alignableLabels,
  ) => {
    const bounds = applyLayout(nodes, type, alignableLabels);

    return {
      nodes,
      width: Math.max(-bounds.min[0], bounds.max[0]) * 2,
      height: Math.max(-bounds.min[1], bounds.max[1]) * 2,
      bounds,
    };
  }
);
