
import { TreeTypes } from "../constants";

export default function (tree) {
  const treeType = tree.getTreeType();

  switch (treeType) {
    case TreeTypes.Rectangular:
    case TreeTypes.Diagonal:
      return "x";

    case TreeTypes.Hierarchical:
      return "y";

    default:
      return undefined;
  }
}
