import { IconLayer, TextLayer } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";

import iconAtlas from "../shapes/icon-atlas-generator";

export default class MetadataLayer extends CompositeLayer {
  renderLayers() {
    return [
      // new BlockLayer({
      //   id: "metadata-blocks",
      //   data: this.props.blocks,
      //   getRadius: this.props.blockLength / 2,
      //   getFillColor: (datum) => datum.colour,
      //   radiusUnits: "pixels",
      //   pickable: true,
      // }),
      new IconLayer({
        id: "metadata-blocks",
        data: this.props.blocks,
        pickable: true,
        iconAtlas: iconAtlas.image,
        iconMapping: iconAtlas.mapping,
        getIcon: () => "square",
        getPixelOffset: this.props.getPixelOffset,
        getSize: this.props.blockLength,
        getColor: (datum) => datum.colour,
        getAngle: (datum) => datum.node.angleDegrees,
        updateTriggers: {
          getPixelOffset: this.props.getPixelOffset,
        },
        autoHighlight: true,
        highlightColor: [ 0, 0, 0, 64 ],
      }),
      new TextLayer({
        id: "metadata-headers",
        data: this.props.headers,
        getSize: this.props.fontSize,
        getAngle: (datum) => datum.angle,
        getColor: this.props.fontColour,
        fontFamily: this.props.fontFamily,
        getPixelOffset: this.props.getPixelOffset,
        updateTriggers: {
          getPixelOffset: this.props.getPixelOffset,
        },
        getTextAnchor(datum) {
          return datum.inverted ? "end" : "start";
        },
        visible: this.props.hasHeaders,
      }),
    ];
  }
}
MetadataLayer.componentName = "MetadataLayer";
