import pixelOffsetsSelector from "../selectors/pixel-offsets";
import viewStateSelector from "../selectors/viewState";
import zoomSelector from "../selectors/zoom";
import zoomToScale from "../utils/zoom-to-scale";
import nodesSelector from "../selectors/nodes";

export default function (tree, newView = {}) {
  const currentView = viewStateSelector(tree);
  const newZoom = newView.zoom ?? currentView.zoom;
  const newCentre = newView.centre ?? currentView.centre;

  if (newZoom < currentView.zoom) {
    const { bounds } = nodesSelector(tree);
    const pixelOffsets = pixelOffsetsSelector(tree);
    const scale = zoomToScale(zoomSelector(tree));
    const worldBounds = {
      min: [
        bounds.min[0] - (pixelOffsets.left / scale),
        bounds.min[1] - (pixelOffsets.top / scale),
      ],
      max: [
        bounds.max[0] + (pixelOffsets.right / scale),
        bounds.max[1] + (pixelOffsets.bottom / scale),
      ],
    };
    const padding = tree.getPadding();
    if (
      tree.isPointOnScreen(
        tree.projectPoint(
          worldBounds.min,
          scale,
        ),
        padding,
      )
      &&
      tree.isPointOnScreen(
        tree.projectPoint(
          worldBounds.max,
          scale,
        ),
        padding,
      )
    ) {
      return;
    }
  }

  tree.setState(
    {
      fixedScale: newView.fixedScale,
      centre: newCentre,
      zoom: newZoom,
    },
    "viewport",
  );
}
