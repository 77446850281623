export default function (tree, filename = "tree") {
  const blob = tree.exportSVG();

  // blob.text().then((svg) => {
  //   document.getElementById("svg").innerHTML = svg;
  // });

  const url = URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.download = `${filename}.svg`;
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
}
