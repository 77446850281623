import createSelector from "../utils/createSelector";

import nodeSelector from "./nodes";
import pixelOffsetsSelector from "./pixel-offsets";
import scaleSelector from "./scale";

const worldBoundsSelector = createSelector(
  nodeSelector,
  pixelOffsetsSelector,
  scaleSelector,
  (
    { bounds },
    pixelOffsets,
    scale,
  ) => {
    return {
      min: [
        bounds.min[0] - (pixelOffsets.left / scale),
        bounds.min[1] - (pixelOffsets.top / scale),
      ],
      max: [
        bounds.max[0] + (pixelOffsets.right / scale),
        bounds.max[1] + (pixelOffsets.bottom / scale),
      ],
    };
  }
);
worldBoundsSelector.displayName = "world-bounds";

export default worldBoundsSelector;
