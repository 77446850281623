import hasInteraction from "../utils/has-interaction";

export default function (tree, info, event) {
  const hasHighlight = hasInteraction(tree, "highlight");
  // const hasTooltip = hasInteraction(tree, "tooltip");

  if (hasHighlight) {
    const node = tree.nodeFromLayer(info.layer, info.object);
    if (hasHighlight) {
      tree.highlightNode(node, event.srcEvent.metaKey || event.srcEvent.ctrlKey);
    }

    // if (hasTooltip) {
    //   let tooltipText = "";

    //   if (node) {
    //     const nodeLabel = (node.label ?? node.id);
    //     if (node.isLeaf) {
    //       tooltipText = nodeLabel;
    //     }
    //     else {
    //       tooltipText += `Leaves in subtree: ${node.totalLeaves}.`;
    //       if (node.label) {
    //         tooltipText += ` Subtree label: ${(node.label)}.`;
    //       }
    //     }

    //     if (info.object.columnName && info.object.block) {
    //       tooltipText += `. ${info.object.columnName}: ${info.object.block.label ?? info.object.block.colour}.`;
    //     }
    //   }

    //   tree.setTooltip(tooltipText);
    // }
  }
}
