import createSelector from "../utils/createSelector";

import metadataColumnWidthSelector from "./metadataColumnWidth";

export default createSelector(
  metadataColumnWidthSelector,
  (columnWidths) => {
    let total = 0;
    for (const key of Object.keys(columnWidths)) {
      total += columnWidths[key];
    }
    return total;
  }
);
