import defaults from "../defaults";
import calculateScaledCentre from "../utils/calculate-scaled-centre";
import zoomToScale from "../utils/zoom-to-scale";

export default function (tree, stepZoom, screenPoint = tree.getCanvasCentrePoint()) {
  const axis = tree.getStepZoomingAxis();
  if (axis) {
    const newZoom = stepZoom ?? defaults.stepZoom;
    if (screenPoint) {
      const startZoom = tree.state.stepZoom ?? defaults.stepZoom;
      const startScale = zoomToScale(startZoom);
      const newScale = zoomToScale(newZoom);
      const centre = calculateScaledCentre(tree, startScale, newScale, screenPoint, axis);
      tree.setState({
        centre,
        stepZoom: newZoom,
      });
    }
    else {
      tree.setState({ stepZoom: newZoom });
    }
  }
}
