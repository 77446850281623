import appendToArray from "../utils/appendToArray";

export default function (tree, nodeOrId) {
  const node = tree.getNodeById(nodeOrId);
  if (node) {
    const collapsedIds = appendToArray(tree.state.collapsedIds, node.id);
    tree.setState({
      collapsedIds,
    });
  }
}
