/* eslint no-else-return: 0 */

export default function (tree, nodeOrId) {
  const nodes = tree.getNodes();
  if (typeof nodeOrId === "string" || typeof nodeOrId === "number") {
    const node = nodes.ids[nodeOrId];

    if (!node) {
      throw new Error(`Cannot find node of ID= ${nodeOrId}`);
    }

    return node;
  }

  const node = nodes.ids[nodeOrId.id];

  if (!node || node !== nodeOrId) {
    throw new Error(`Node not found ${nodeOrId}`);
  }

  return node;
}
