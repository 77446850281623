export default function (tree, x, y) {
  const info = tree.deck.pickObject({ x, y });
  if (info) {
    const node = tree.nodeFromLayer(info.layer, info.object);
    return node;
  }
  else {
    return null;
  }
}
