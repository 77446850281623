import { TreeTypes } from "../constants";
import createSelector from "../utils/createSelector";

import treeTypeSelector from "./treeType";

export default createSelector(
  treeTypeSelector,
  (tree) => tree.state.showLabels && tree.state.alignLabels,
  (treeType, alignLabels) => {
    switch (treeType) {
      case TreeTypes.Circular:
      case TreeTypes.Hierarchical:
      case TreeTypes.Rectangular:
        return alignLabels;

      case TreeTypes.Diagonal:
      case TreeTypes.Radial:
        return false;

      default:
        return undefined;
    }
  }
);
