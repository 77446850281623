import { TextLayer, LineLayer } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";
import { DataFilterExtension } from "@deck.gl/extensions";

import { Angles } from "../../constants";
// import DashedLineLayer from "./dashed-line-layer";

const textAnchors = {
  center: "middle",
  left: "start",
  right: "end",
};

function getTextAnchor(datum) {
  return textAnchors[datum.textAlign];
}

function getAngle(datum) {
  const degrees = (datum.transform.angle / Angles.Degrees360) * 360;
  if (datum.inverted) {
    return 180 - degrees;
  }
  return 360 - degrees;
}

function getText(datum) {
  return datum.label;
}

export default class LabelsLayer extends CompositeLayer {

  renderLayers() {
    return [
      new TextLayer({
        id: "labels-text",
        data: this.props.data,
        getSize: this.props.fontSize,
        getPosition: this.props.getTextPosition,
        getText,
        getAngle,
        getColor: this.props.fontColour,
        fontFamily: this.props.fontFamily,
        getTextAnchor,
      }),

      new LineLayer({
        id: "labels-lines",
        data: this.props.data,
        getSourcePosition: this.props.getTextPosition,
        getTargetPosition: this.props.getNodePosition,
        getColor: this.props.lineColour,
        getWidth: this.props.lineWidth,
        opacity: 0.54,
        getFilterValue: (x) => (x.node ? 1 : 0),
        filterRange: [1, 1],
        extensions: [
          new DataFilterExtension({ filterSize: 1 }),
        ],
      }),

    ];
  }

}

LabelsLayer.defaultProps = {
  // Shared accessors

  // text accessors
  getTextPosition: { type: "accessor", value: (node) => [ node.x, node.y ] },
  fontSize: 16,
  fontFamily: "Monaco, monospace",
  fontColour: [ 0, 0, 0, 255 ],

  // line properties
  getNodePosition: { type: "accessor", value: (datum) => (datum.node ? [ datum.node.x, datum.node.y ] : [ 0, 0 ]) },
  lineWidth: 1,
  lineColour: [ 0, 0, 0, 255 ],
};

LabelsLayer.componentName = "LabelsLayer";
