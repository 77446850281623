// function naturalLogScale(maxX, minY, maxY) {
//   const c = minY;
//   const k = (maxY - minY) / Math.log10(maxX);
//   return (value) => k * Math.log10(value) + c;
// }

// export default function (minX, maxX, minY, maxY) {
//   const correction = minX - 1;
//   const scale = logScale(1, maxX - correction, minY, maxY);
//   return (value) => scale(value - correction);
// }

// const testA = logarithmicScale([1, 6], [0, 255 ]);
// console.log("testA", testA(1), testA((6 + 1)/2), testA(6))
// const testB = logarithmicScale([11, 16], [0, 255 ]);
// console.log("testB", testB(11), testB((16 + 11)/2), testB(16))
// const testC = logarithmicScale([-1, 4], [0, 255 ]);
// console.log("testC", testC(-1), testC((4 + -1)/2), testC(4))
// const testD = logarithmicScale([1, 1.2], [0, 255 ]);
// console.log("testD", testD(1), testD((1.2 + 1)/2), testD(1.2))
// const testE = logarithmicScale([0.5, 0.7], [0, 255 ]);
// console.log("testE", testE(0.5), testE((0.7 + 0.5)/2), testE(0.7))

function logScale(domainExtent, rangeExtent) {
  const [ domainMin, domainMax ] = domainExtent;
  const [ rangeMin, rangeMax ] = rangeExtent;

  const correction = domainMin - 1;
  const c = rangeMin;
  const k = (rangeMax - rangeMin) / Math.log10(domainMax - correction);
  return (value) => k * Math.log10(value - correction) + c;
}

export default logScale;
