import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import nodesSelector from "../../selectors/nodes";
import { EmptyArray } from "../../constants";

export default createSelector(
  nodesSelector,
  (tree) => tree.state.highlightedId ?? defaults.highlightedId,
  (
    { nodes },
    highlightedId,
  ) => {
    if ((highlightedId ?? undefined) !== undefined) {
      const node = nodes.ids[highlightedId];
      return [ node ];
    }
    return EmptyArray;
  }
);
