import createSelector from "../utils/createSelector";

import lineAlphaSelector from "./line-alpha";
import strokeColourSelector from "./strokeColour";

const lineColourSelector = createSelector(
  strokeColourSelector,
  lineAlphaSelector,
  (
    strokeColour,
    lineAlpha,
  ) => {
    const colour = [
      strokeColour[0],
      strokeColour[1],
      strokeColour[2],
      lineAlpha,
    ];
    return colour;
  }
);

export default lineColourSelector;
