import calculateNodeBounds from "../utils/calculate-node-bounds";

export default function (tree) {
  const nodes = tree.getNodes();
  const bounds = calculateNodeBounds(nodes.leaves);

  const midPointOnScreen = tree.projectPoint(bounds.centre);

  return midPointOnScreen;
}
