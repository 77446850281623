import { TreeTypes } from "../constants";
import createSelector from "../utils/createSelector";
import zoomToScale from "../utils/zoom-to-scale";

// import alignLeafLabelsSelector from "./alignLeafLabels";
import baseLayoutSelector from "./baseLayout";
import drawingAreaSelector from "./drawingArea";
import treeTypeSelector from "./treeType";
import branchZoomSelector from "./branch-zoom";

const branchRatioSelector = createSelector(
  baseLayoutSelector,
  treeTypeSelector,
  drawingAreaSelector,
  // (tree) => alignLeafLabelsSelector(tree),
  (
    baseLayout,
    treeType,
    area,
    // alignableLabels,
  ) => {
    const { nodes } = baseLayout;
    if (nodes.root.totalSubtreeLength > 0) {
      switch (treeType) {
        case TreeTypes.Diagonal:
        case TreeTypes.Rectangular:
          return area.width / nodes.root.totalSubtreeLength;

        case TreeTypes.Hierarchical:
          return area.height / nodes.root.totalSubtreeLength;

        case TreeTypes.Circular:
        case TreeTypes.Radial: {
          const { width, height } = baseLayout;
          // if (alignableLabels) {
          // width = nodes.root.totalSubtreeLength * 2;
          // height = nodes.root.totalSubtreeLength * 2;
          // }
          const xAspectRatio = area.width / width;
          const yAspectRatio = area.height / height;
          if (xAspectRatio > yAspectRatio) {
            return (area.height) / height;
          }
          else {
            return (area.width) / width;
          }
        }
      }
    }

    throw new Error("Cannot compute default branch scale");
  }
);
branchRatioSelector.displayName = "branchRatio";

const branchScaleSelector = createSelector(
  branchRatioSelector,
  branchZoomSelector,
  (
    stepRatio,
    stepZoom,
  ) => {
    const branchScale = zoomToScale(stepZoom);
    return stepRatio * branchScale;
  },
);
branchScaleSelector.displayName = "step-scale";

export default branchScaleSelector;
