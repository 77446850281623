/* eslint-disable no-plusplus */

// Finds the bounds of the tree (min and max points
export default function calculateNodeBounds(nodes, firstIndex = 0, lastIndex = nodes.length - 1, x = "x", y = "y") {
  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  // Loop over all nodes while keeping track of the min and max points
  for (let i = firstIndex; i <= lastIndex; i++) {
    const node = nodes[i];

    if (node[x] < minX) {
      minX = node[x];
    }
    if (node[y] < minY) {
      minY = node[y];
    }
    if (node[x] > maxX) {
      maxX = node[x];
    }
    if (node[y] > maxY) {
      maxY = node[y];
    }
  }

  const midX = (maxX + minX) / 2;
  const midY = (maxY + minY) / 2;

  const width = (maxX - minX);
  const height = (maxY - minY);

  return {
    min: [ minX, minY ],
    max: [ maxX, maxY ],
    centre: [ midX, midY ],
    width,
    height,
  };
}
