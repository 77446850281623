import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

export default createSelector(
  (tree) => tree.state.haloWidth || defaults.haloWidth,
  (tree) => tree.state.nodeSize || defaults.nodeSize,
  (tree) => tree.state.haloRadius || defaults.haloRadius,
  (
    width,
    nodeSize,
    haloRadius,
  ) => {
    const radius = Math.max(nodeSize * 0.75, haloRadius);
    const leafRadius = radius + (width / 2);
    const internalRadius = width + (width / 2);
    return {
      width,
      leaf: leafRadius,
      internal: internalRadius,
    };
  }
);
