/**
 * Checks whether a point in the screen space is visible on the screen.
 *
 * @param {Object} tree - a tree instance
 * @param {Point} screenPoint - a point in the screen space (i.e. in pixels).
 * @param {Number} padding - an optional padding in pixels.
 *
 * @example <caption>Example usage of iPointOnScreen.</caption>
 * // returns true
 * tree.iPointOnScreen([ 0, 0 ]);
 *
 * @returns {Object} A point
 */
export default function iPointOnScreen(tree, screenPoint, padding = 0) {
  const size = tree.getCanvasSize();
  const inside = (
    screenPoint[0] >= padding
    &&
    screenPoint[0] <= size.width - padding
    &&
    screenPoint[1] >= padding
    &&
    screenPoint[1] <= size.height - padding
  );
  return inside;
}
