import { ScatterplotLayer } from "@deck.gl/layers";

// import vs from "./circle-sector-layer-vertex.glsl";
// import fs from "./circle-sector-layer-fragment.glsl";

class CircleSectorLayer extends ScatterplotLayer {
  getShaders(id) {
    return {
      ...super.getShaders(),
      // vs,
      // fs,
      inject: {
        "vs:#decl": `
          attribute float instanceStartAngle;
          attribute float instanceEndAngle;
          varying vec2 sectorStart;
          varying vec2 sectorEnd;        
        `,
        "vs:#main-start": `
          sectorStart = vec2(cos(instanceStartAngle), sin(instanceStartAngle));
          sectorEnd = vec2(cos(instanceEndAngle), sin(instanceEndAngle));              
        `,
        "fs:#decl": `
          varying vec2 sectorStart;
          varying vec2 sectorEnd;        
        `,
        "fs:DECKGL_FILTER_COLOR": `
          if (-sectorStart.x * geometry.uv.y + sectorStart.y * geometry.uv.x > 0.0) {
            discard;
          }
          if (-sectorEnd.x * geometry.uv.y + sectorEnd.y * geometry.uv.x < 0.0) {
            discard;
          }
        `,
      },
    };
  }

  initializeState() {
    super.initializeState();
    this.getAttributeManager().addInstanced({
      instanceStartAngle: {
        size: 1,
        transition: true,
        accessor: "getStartAngle",
        defaultValue: 1,
      },
      instanceEndAngle: {
        size: 1,
        transition: true,
        accessor: "getEndAngle",
        defaultValue: 1,
      },
    });
  }
}
CircleSectorLayer.componentName = "CircleSectorLayer";

export default CircleSectorLayer;
