import viewStateSelector from "../selectors/viewState";

export default function (tree) {
  tree.renderer.busy = true;

  //#region Check if render is suspended
  if (tree.deferred.count > 0) {
    tree.deferred.render = true;
    tree.log && tree.log("render is suspended"); // eslint-disable-line no-unused-expressions
    return;
  }
  //#endregion

  const t0 = performance.now();

  tree.getNodes();

  const t1 = performance.now();

  const deckglLayers = [];

  for (const layer of tree.layers) {
    const visible = layer.isVisible(tree.state);
    if (visible) {
      layer.deckglLayer = layer.renderLayer(tree);
      deckglLayers.push(layer.deckglLayer);
    }
    else if (layer.deckglLayer) {
      if (layer.deckglLayer.visible) {
        layer.deckglLayer.setState({ visible: false });
      }
    }
  }

  const viewState = viewStateSelector(tree);

  const t2 = performance.now();

  // console.log(
  //   deckglLayers[0] === tree.deck.props.layers[0]
  //   ,
  //   deckglLayers[1] === tree.deck.props.layers[1]
  // )

  tree.deck.setProps({
    viewState,
    width: tree.state.size.width,
    height: tree.state.size.height,
    // layers: tree.deck.props.layers.length ? tree.deck.props.layers : deckglLayers,
    layers: deckglLayers,
  });

  const t3 = performance.now();

  tree.log // eslint-disable-line no-unused-expressions
    && tree.log(
      "layout took ", (t1 - t0), " milliseconds.",
      "\nrender took ", (t2 - t1), " milliseconds.",
      "\nupdate took ", (t3 - t2), " milliseconds.",
    );

  tree.renderer.busy = false;
}
