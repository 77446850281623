/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-spread */
/* eslint prefer-rest-params: 0 */

let total = 0;

export const selectors = [];

function createSelector(...dependencies) {
  const resultFunc = dependencies.pop();

  let lastState = null;
  let lastArgs = null;
  let lastResult = null;
  const selector = function (context) {
    if (lastState === null || context.state === null || lastState !== context.state) {
      const args = [];

      for (let i = 0; i < dependencies.length; i++) {
        args.push(dependencies[i].call(null, context));
      }

      args.push(context);

      let recompute = false;
      if (lastArgs === null || args === null || lastArgs.length !== args.length) {
        recompute = true;
      } else {
        for (let i = 0; i < args.length; i++) {
          if ((lastArgs[i] !== args[i])) {
            recompute = true;
            break;
          }
        }
      }
      if (recompute) {
        const t0 = performance.now();
        lastResult = resultFunc.apply(null, args);
        const t1 = performance.now();
        if (selector.displayName) {
          total += (t1 - t0);
          context.log && context.log("selector %s took:", selector.displayName, t1 - t0, /* total */);
        }
      }

      lastArgs = args;
    }

    lastState = context.state;
    return lastResult;
  };
  return selector;
}

export default function () {
  const cache = new Map();
  const dependencies = arguments;
  const selector = function (state) {
    const cacheKey = state.id || "";

    let cachedSelector = cache.get(cacheKey);

    if (cachedSelector === undefined) {
      cachedSelector = createSelector.apply(null, dependencies);
      cachedSelector.displayName = selector.displayName;
      cache.set(cacheKey, cachedSelector);
    }

    return cachedSelector.apply(null, arguments);
  };
  selector.cache = cache;
  selectors.push(selector);
  return selector;
}
