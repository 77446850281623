import getPostorderTraversal from "../utils/getPostorderTraversal";

function reroot(tree, treeRoot, parent, sourceNode) {
  const newRoot = {
    branchLength: sourceNode.branchLength,
    children: [],
    parent,
  };
  parent.children.push(newRoot);

  for (const child of sourceNode.parent.children) {
    if (child !== sourceNode) {
      newRoot.children.push(child);
    }
  }

  if (sourceNode.parent.parent && sourceNode.parent !== treeRoot) {
    reroot(tree, treeRoot, newRoot, sourceNode.parent);
  }
}

function getSource(newRoot) {
  const postorderTraversal = getPostorderTraversal(newRoot);
  const subtrees = [];
  for (const node of postorderTraversal) {
    if (node.isLeaf) {
      subtrees.push(`${node.id}:${node.branchLength}`);
    } else if (node !== newRoot) {
      const chunks = subtrees.splice(subtrees.length - node.children.length, node.children.length);
      subtrees.push(`(${chunks.join(",")}):${node.branchLength}`);
    }
  }

  return `(${subtrees.join(",")});`;
}

export default function (tree) {
  const nodes = tree.getNodes();
  const pathsFromRoot = [];
  for (const leaf of nodes.leaves) {
    const parentNodes = [];
    let node = leaf;
    while (node.parent) {
      parentNodes.unshift(node.parent);
      node = node.parent;
    }
    pathsFromRoot.push(parentNodes);
  }

  let longestDistance = 0;
  let longestDistanceLcaLeafA;
  let longestDistanceLcaLeafB;
  let longestDistanceLca;
  for (let i = 0; i < nodes.leaves.length; i++) {
    for (let j = i + 1; j < nodes.leaves.length; j++) {
      const leafI = nodes.leaves[i];
      const leafJ = nodes.leaves[j];
      // console.log(leafI.label, leafJ.label);
      let lca;
      for (let parentIndex = 0; parentIndex < pathsFromRoot[i].length && parentIndex < pathsFromRoot[j].length; parentIndex++) {
        if (pathsFromRoot[i][parentIndex] !== pathsFromRoot[j][parentIndex]) {
          break;
        }
        lca = pathsFromRoot[i][parentIndex];
      }

      const distance = leafI.distanceFromRoot + leafJ.distanceFromRoot - 2 * lca.distanceFromRoot;
      if (distance > longestDistance) {
        longestDistance = distance;
        longestDistanceLca = lca;
        longestDistanceLcaLeafA = leafI;
        longestDistanceLcaLeafB = leafJ;
      }
    }
  }

  // console.log(longestDistanceLcaLeafA, longestDistanceLcaLeafB);

  const midpointDistance = longestDistance / 2;
  const leaf = (longestDistanceLcaLeafA.distanceFromRoot > midpointDistance) ? longestDistanceLcaLeafA : longestDistanceLcaLeafB;
  const path = pathsFromRoot[nodes.leaves.indexOf(leaf)];
  path.push(leaf);
  const distance = leaf.distanceFromRoot - midpointDistance;
  let midpointNode;
  for (const node of path) {
    if (node.distanceFromRoot > distance) {
      midpointNode = node;
      break;
    }
  }

  const newRootNode = {
    branchLength: 0,
    children: [],
  };

  midpointNode.branchLength = midpointNode.parent.distanceFromRoot - distance;
  reroot(tree, nodes.root, newRootNode, midpointNode);

  newRootNode.children.push(midpointNode);
  midpointNode.branchLength = midpointNode.distanceFromRoot - distance;
  midpointNode.parent = newRootNode;

  const source = {
    type: "newick",
    original: tree.state.source,
    data: getSource(newRootNode),
  };

  tree.setSource(source);
}
