// import { IconLayer } from "@deck.gl/layers";

import createSelector from "../../utils/createSelector";

import nodeSizeSelector from "../../selectors/nodeSize";
// import visibleLeafNodesSelector from "./visible-nodes";
import visibleLeafNodesSelector from "./visible-leaf-nodes";
import borderColourSelector from "./border-colour";
import shapeBorderWidthSelector from "./shape-border-width";

import ShapesLayer from "./shapes-layer";
// import BorderedIconLayer from "./bordered-icon-layer";
// import generateIconAtlasMapping from "./generate-icon-atlas-mapping";

// const { atlas, mapping } = generateIconAtlasMapping();

const getIcon = (node) => node.shape;
const getPosition = (node) => [ node.x, node.y ];
const getColor = (node) => node.fillColour;

export default createSelector(
  visibleLeafNodesSelector,
  nodeSizeSelector,
  (tree) => tree.state.showShapeBorders,
  shapeBorderWidthSelector,
  borderColourSelector,
  // sizeAccessorSelector,
  (
    leafNodes,
    nodeSize,
    showShapeBorders,
    shapeBorderWidth,
    borderColour,
    // sizeAccessor,
  ) => {
    // const layer = new BorderedIconLayer({
    //   id: "shapes",
    //   data: leafNodes,
    //   pickable: true,
    //   iconAtlas: atlas,
    //   iconMapping: mapping,
    //   getIcon,
    //   getPosition,
    //   getSize: nodeSize,
    //   getColor,
    //   getBorderColor: borderColour,
    //   stroked: showShapeBorders,
    //   updateTriggers: {
    //     getSize: nodeSize,
    //   },
    // });

    const layer = new ShapesLayer({
      id: "shapes",
      data: leafNodes,
      // data: leafNodes(),
      // iconAtlas: atlas,
      // iconMapping: mapping,
      getIcon,
      getPosition,
      getSize: nodeSize,
      getColor,
      showBorders: showShapeBorders,
      borderColour,
      borderSizeScale: 1 + ((2 * shapeBorderWidth) / nodeSize),
      // borderWidth: shapeBorderWidth,
      updateTriggers: {
        getSize: nodeSize,
      },
    });

    return layer;
  }
);
