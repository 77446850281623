export default function (tree, layer, object) {
  let node;

  if (layer && object) {
    if (layer.id === "shapes") {
      node = object;
    }
    else if (layer.id === "edges") {
      if ((object.preIndex ?? undefined) !== undefined) {
        const nodes = tree.getNodes();
        node = nodes.preorderTraversal[object.preIndex];
      }
    }
    else if (layer.id === "piecharts" || layer.id === "metadata") {
      node = object.node;
    }
    else {
      tree.log && tree.log(`Unknown layer ${layer.id}`); // eslint-disable-line no-unused-expressions
    }
  }

  return node ?? null;
}
