/* eslint-disable no-plusplus */
/* eslint-disable prefer-rest-params */

import applyPlugins from "./utils/applyPlugins";
import bindMethods from "./utils/bindMethods";
import defaults from "./defaults";
import methods from "./methods";

let lastId = 0;

export default function (view, options = {}, plugins = []) {
  const tree = {
    view,
    state: {},
    deferred: {
      count: 0,
      render: false,
    },
    layers: [],
    renderer: {},
    defaults,
  };

  bindMethods(tree, methods);

  const size = options.size || view.getBoundingClientRect();
  const id = options.id || `tree-${lastId++}`;
  tree.state = {
    ...options,
    id,
    size,
  };

  if (plugins.length) {
    applyPlugins(tree, plugins);
  }

  tree.init();
  tree.setState(tree.state);

  return tree;
}
