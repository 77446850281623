import viewStateSelector from "../selectors/viewState";
import targetToCentre from "./targetToCentre";

export default function calculateScaledCentre(tree, startScale, newScale, screenPoint, axis = "xy") {
  const zoomScale = tree.getScale();

  const scaleRatio = newScale / startScale;

  const treePoint = tree.unprojectPoint(screenPoint);

  const scaledTreePoint = [
    treePoint[0] * scaleRatio,
    treePoint[1] * scaleRatio,
  ];

  const scaledScreenPoint = tree.projectPoint(scaledTreePoint);

  const pixelDeltaX = (axis === "x" || axis === "xy") ? (scaledScreenPoint[0] - screenPoint[0]) / zoomScale : 0;
  const pixelDeltaY = (axis === "y" || axis === "xy") ? (scaledScreenPoint[1] - screenPoint[1]) / zoomScale : 0;

  const viewState = viewStateSelector(tree);
  const newTarget = [
    viewState.target[0] + pixelDeltaX,
    viewState.target[1] + pixelDeltaY,
  ];

  const newCentre = targetToCentre(tree, newTarget);

  return newCentre;
}
