import { Deck, OrthographicView } from "@deck.gl/core";
import hasInteraction from "../utils/has-interaction";

import targetToCentre from "../utils/targetToCentre";
import createController from "../utils/create-controller";

export default function (tree) {
  let timeoutId;

  tree.deck = new Deck({
    // container: "phylocanvas",
    pickingRadius: 2,
    useDevicePixels: false,
    views: new OrthographicView({
      controller: (hasInteraction(tree, "pan") || hasInteraction(tree, "zoom")) ? createController(tree) : false,
    }),
    initialViewState: { target: [0, 0, 0], zoom: 0 },
    layers: [],
    onViewStateChange: ({ viewState }) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => tree.setState({ fixedScale: null }),
        500,
      );
      tree.setView({
        centre: targetToCentre(tree, viewState.target),
        zoom: viewState.zoom,
        fixedScale: tree.state.fixedScale ?? tree.getScale(),
      });
    },
    onHover: tree.handleHover,
    onClick: tree.handleClick,
    // _onMetrics: (metrics) => {
    //   console.log(metrics.fps);
    // },
    // debug: true,
  });

  tree.view.appendChild(tree.deck.canvas);

  const link = document.createElement("a");
  link.href = "https://phylocanvas.org/";
  link.target = "_blank";
  link.style.position = "absolute";
  link.style.bottom = "2px";
  link.style.right = "2px";
  link.style.fontSize = "10px";
  link.style.color = "#000000bf";
  link.style.backgroundColor = "#ffffff80";
  link.style.textDecoration = "none";
  link.style.fontWeight = "500";
  tree.view.style.position = "relative";
  const image = document.createElement("img");
  image.src = "https://phylocanvas.org/img/phylo.icon.final.png";
  image.width = "14";
  image.style.verticalAlign = "middle";
  link.appendChild(image);
  link.appendChild(document.createTextNode("Phylocanvas"));
  tree.view.appendChild(link);

  tree.createLayers();
}
