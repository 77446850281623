import defaults from "../defaults";
import createSelector from "../utils/createSelector";

import drawingAreaSelector from "./drawingArea";
import sizeSelector from "./size";

export default createSelector(
  (tree) => tree.state.centre ?? defaults.centre,
  sizeSelector,
  drawingAreaSelector,
  (
    centre,
    size,
    area,
  ) => {
    const x = (centre[0]) * size.width;
    const y = (centre[1]) * size.height;

    // const x = centre[0] * area.width + area.left;
    // const y = centre[1] * area.height + area.top;

    return {
      x,
      y,
    };
  }
);
