import defaults from "../defaults";

import createSelector from "../utils/createSelector";

import sizeSelector from "./size";
import drawingAreaSelector from "./drawingArea";

export default createSelector(
  sizeSelector,
  drawingAreaSelector,
  (
    size,
    area,
  ) => {
    const canvasCentre = [
      size.width / 2,
      size.height / 2,
    ];
    const areaCentre = [
      area.width / 2 + area.left,
      area.height / 2 + area.top,
    ];
    const diff = [
      canvasCentre[0] - areaCentre[0],
      canvasCentre[1] - areaCentre[1],
    ];
    return diff;
  }
);
