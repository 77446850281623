import { Angles } from "../../constants";

import createSelector from "../../utils/createSelector";

import alignLeafLabelsSelector from "../../selectors/alignLeafLabels";
import branchScaleSelector from "../../selectors/branch-scale";
import metadataBlockLengthSelector from "../../selectors/metadataBlockLength";
import metadataBlockPaddingSelector from "../../selectors/metadataBlockPadding";
import metadataColumnWidthSelector from "../../selectors/metadataColumnWidth";
import metadataHeaderStyleSelector from "../../selectors/metadataHeaderStyle";
import styledNodesSelector from "../../selectors/styled-nodes";
import scaleSelector from "../../selectors/scale";
import showMetadataHeadersSelector from "../../selectors/showMetadataHeaders";
import showMetadataLabelsSelector from "../../selectors/showMetadataLabels";
import stepScaleSelector from "../../selectors/step-scale";
import blockOffsetSelector from "./block-offset";

export default createSelector(
  styledNodesSelector,
  alignLeafLabelsSelector,
  showMetadataLabelsSelector,
  (tree) => tree.state.blocks,
  metadataColumnWidthSelector,
  branchScaleSelector,
  metadataBlockLengthSelector,
  (
    { nodes },
    shouldAlignLabels,
    hasMetadataLabels,
    metadataColumns,
    columnWidths,
    branchScale,
    blockLength,
  ) => {
    const data = [];

    const firstLeaf = nodes.postorderTraversal[nodes.root.postIndex - nodes.root.totalNodes + 1];
    const inverted = (firstLeaf.angle > Angles.Degrees90) && (firstLeaf.angle < Angles.Degrees270);

    const nodePositionOffset = (
      shouldAlignLabels
        ?
        (branchScale * (nodes.root.totalSubtreeLength - firstLeaf.distanceFromRoot))
        :
        0
    );

    const nodePosition = [
      firstLeaf.x + (nodePositionOffset * Math.cos(firstLeaf.angle)),
      firstLeaf.y + (nodePositionOffset * Math.sin(firstLeaf.angle)),
    ];

    let angle = firstLeaf.angle;
    if (!hasMetadataLabels) {
      angle -= Angles.Degrees90;
    }

    let xOffset = 0;
    const offsetY = -blockLength;
    for (let index = 0; index < metadataColumns.length; index++) {
      const columnName = metadataColumns[index];
      const columnWidth = columnWidths[columnName];

      data.push({
        node: firstLeaf,
        inverted,
        position: nodePosition,
        offsetX: xOffset + (columnWidth / 2),
        offsetY,
        text: columnName,
        angle: 360 - (angle / Angles.Degrees360) * 360,
      });

      xOffset += columnWidth;
    }

    return data;
  }
);
