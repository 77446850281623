/**
 * Translates a scalar value on the screen to a scalar value on the tree plane.
 *
 * @param {Object} tree - a tree instance
 * @param {Number} value - the value (in pixels) on the screen.
 *
 * @example <caption>Example usage of unprojectValue with a tree transform equals { z: 2 }.</caption>
 * // returns 5
 * unprojectValue(tree, 10);
 *
 * @returns {Number} The value on the tree plane
 */
export default function (tree, value) {
  return (value / ((tree.state.transform && tree.state.transform.z) || 1));
}
