export default function targetToCentre(tree, target) {
  const size = tree.getCanvasSize();
  const area = tree.getDrawingArea();
  const canvasCentre = [
    size.width / 2,
    size.height / 2,
  ];
  const areaCentre = [
    area.width / 2 + area.left,
    area.height / 2 + area.top,
  ];
  const diff = [
    canvasCentre[0] - areaCentre[0],
    canvasCentre[1] - areaCentre[1],
  ];

  return [
    ((-target[0] + diff[0]) / size.width) + 0.5,
    ((-target[1] + diff[1]) / size.height) + 0.5,
  ];
}
