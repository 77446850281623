export default function (tree, nodeOrId, options = {}) {
  const nodes = tree.getNodes();
  const rootNode = nodeOrId ? tree.getNodeById(nodeOrId) : nodes.root;
  const { excludeCollapsed = true } = options;

  const strings = [];
  for (let i = rootNode.postIndex - rootNode.totalNodes + 1; i < rootNode.postIndex; i++) {
    const node = nodes.postorderTraversal[i];
    if (node.isLeaf) {
      strings.push(`${node.label || node.id}:${node.branchLength}`);
    }
    else if (excludeCollapsed && node.isCollapsed) {
      strings.push(`[${node.totalLeaves} hidden ${node.totalLeaves === 1 ? "leaf" : "leaves"}]:${node.branchLength}`);
    }
    else {
      const chunks = strings.splice(strings.length - node.children.length, node.children.length);
      strings.push(`(${chunks.join(",")}):${node.branchLength}`);
    }
  }

  return `(${strings.join(",")});`;
}
