/**
 * Checks whether a point in the world space is visible on the screen.
 *
 * @param {Object} tree - a tree instance
 * @param {Point} worldPoint - a point in the world space.
 * @param {Number} padding - an optional padding in pixels.
 *
 * @example <caption>Example usage of isWorldPointOnScreen.</caption>
 * // returns true
 * tree.isWorldPointOnScreen([ 0, 0 ]);
 *
 * @returns {Object} A point
 */
export default function isWorldPointOnScreen(tree, worldPoint, padding = 0) {
  const pointOnScreen = tree.projectPoint(worldPoint);
  return tree.isPointOnScreen(pointOnScreen, padding);
}
