/**
 * Translates a scalar value on the tree plane to a scalar value on the screen.
 *
 * @param {Object} tree - a tree instance
 * @param {Number} value - the value on the tree plane.
 *
 * @example <caption>Example usage of projectValue with a tree transform equals { z: 2 }.</caption>
 * // returns 10
 * projectValue(tree, 5);
 *
 * @returns {Number} The value on the screen in pixels
 */
export default function (tree, value) {
  return value * (tree.state.transform && tree.state.transform.z);
}
