import createSelector from "../../utils/createSelector";

import blockOffsetSelector from "./block-offset";

export default createSelector(
  blockOffsetSelector,
  (
    blockOffset,
  ) => {
    return (datum) => {
      if (datum.offsetY) {
        const offsetX = (datum.offsetX + blockOffset);
        return [
          offsetX * Math.cos(datum.node.angle) + datum.offsetY * Math.sin(datum.node.angle),
          offsetX * Math.sin(datum.node.angle) + datum.offsetY * Math.cos(datum.node.angle),
        ];
      }
      else {
        const offset = (datum.offsetX + blockOffset);
        return [
          offset * Math.cos(datum.node.angle),
          offset * Math.sin(datum.node.angle),
        ];
      }
    };
  }
);
