import { TreeTypes } from "../constants";

export default function isOrthogonal(tree) {
  const treeType = tree.getTreeType();

  switch (treeType) {
    case TreeTypes.Diagonal:
    case TreeTypes.Hierarchical:
    case TreeTypes.Rectangular:
      return true;

    case TreeTypes.Circular:
    case TreeTypes.Radial:
      return false;

    default:
      throw new Error("Invalid tree type");
  }
}
