import { TreeTypes } from "../constants";

import createSelector from "../utils/createSelector";

import baseLayoutSelector from "./baseLayout";
import branchScaleSelector from "./branch-scale";
import stepScaleSelector from "./step-scale";
import treeTypeSelector from "./treeType";

function scaleBounds(bounds, branchScale, stepScale) {
  return {
    min: [
      bounds.min[0] * branchScale,
      bounds.min[1] * stepScale,
    ],
    max: [
      bounds.max[0] * branchScale,
      bounds.max[1] * stepScale,
    ],
  };
}

export default createSelector(
  baseLayoutSelector,
  treeTypeSelector,
  branchScaleSelector,
  stepScaleSelector,
  (baseLayout, type, branchScale, stepScale) => {
    const { nodes, bounds } = baseLayout;
    const firstIndex = nodes.root.postIndex - nodes.root.totalNodes + 1;
    const lastIndex = nodes.root.postIndex;

    if (type === TreeTypes.Circular) {
      nodes.root.x = nodes.root.bx * branchScale;
      nodes.root.y = nodes.root.by * branchScale;
      for (let i = 0; i < nodes.root.totalNodes; i++) {
        const node = nodes.preorderTraversal[nodes.root.preIndex + i];
        node.x = node.bx * branchScale;
        node.y = node.by * branchScale;
        node.dist = node.distanceFromRoot * branchScale;
        node.cx = node.bcx * branchScale;
        node.cy = node.bcy * branchScale;
        node.intercept = node.y - node.slope * node.x;
      }
      return {
        nodes,
        bounds: scaleBounds(
          bounds,
          branchScale,
          branchScale,
        ),
      };
    }

    if (type === TreeTypes.Diagonal) {
      nodes.root.x = nodes.root.bx * stepScale;
      nodes.root.y = nodes.root.by * stepScale;
      for (let i = 1; i < nodes.root.totalNodes; i++) {
        const node = nodes.preorderTraversal[nodes.root.preIndex + i];
        node.x = node.bx * stepScale;
        node.y = node.by * stepScale;
        node.slope = (node.parent.y - node.y) / (node.parent.x - node.x);
        node.intercept = node.y - node.slope * node.x;
      }
      return {
        nodes,
        bounds: scaleBounds(
          bounds,
          branchScale,
          stepScale,
        ),
      };
    }

    if (type === TreeTypes.Hierarchical) {
      for (let i = firstIndex; i <= lastIndex; i++) {
        const node = nodes.postorderTraversal[i];
        node.x = node.bx * stepScale;
        node.y = node.by * branchScale;
      }
      return {
        nodes,
        bounds: scaleBounds(
          bounds,
          branchScale,
          stepScale,
        ),
      };
    }

    if (type === TreeTypes.Radial) {
      nodes.root.x = nodes.root.bx * branchScale;
      nodes.root.y = nodes.root.by * branchScale;
      for (let i = 1; i < nodes.root.totalNodes; i++) {
        const node = nodes.preorderTraversal[nodes.root.preIndex + i];
        node.x = node.bx * branchScale;
        node.y = node.by * branchScale;
        node.slope = (node.parent.y - node.y) / (node.parent.x - node.x);
        node.intercept = node.y - node.slope * node.x;
      }
      return {
        nodes,
        bounds: scaleBounds(
          bounds,
          branchScale,
          branchScale,
        ),
      };
    }

    if (type === TreeTypes.Rectangular) {
      for (let i = firstIndex; i <= lastIndex; i++) {
        const node = nodes.postorderTraversal[i];
        node.x = node.bx * branchScale;
        node.y = node.by * stepScale;
      }

      return {
        nodes,
        bounds: scaleBounds(
          bounds,
          branchScale,
          stepScale,
        ),
      };
    }

    throw new Error("Invalid tree type");
  }
);
