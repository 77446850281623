import createSelector from "../../utils/createSelector";
import lineWidthSelector from "../../selectors/line-width";

import visibleEdgesSelector from "./visible-edges";
import colourAccessorSelector from "./colour-accessor";

import EdgesLayer from "./edges-layer";

export default createSelector(
  visibleEdgesSelector,
  lineWidthSelector,
  colourAccessorSelector,
  (
    visibleEdges,
    lineWidth,
    colourAccessor,
  ) => {
    // console.log({visibleEdges: visibleEdges.lines.length})
    return new EdgesLayer({
      id: "edges",
      data: visibleEdges,
      getColor: colourAccessor,
      getWidth: lineWidth,
    });
  }
);
