import defaults from "../defaults";

export default function (tree, source) {
  tree.setState({
    source,
    rootId: defaults.rootId,
    collapsedIds: defaults.collapsedIds,
    rotatedIds: defaults.rotatedId,
    transform: defaults.transform,
  });
}
