import defaults from "../defaults";
import scaleToZoom from "../utils/scale-to-zoom";

export default function (tree, scale, screenPoint = tree.getCanvasCentrePoint()) {
  const newScale = scale ?? defaults.scale;
  const newZoom = scaleToZoom(newScale);

  tree.setZoom(
    newZoom,
    screenPoint,
  );
}
