import { Angles } from "../../constants";
/* eslint-disable no-plusplus */

import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import styledNodesSelector from "../../selectors/styled-nodes";

export default createSelector(
  styledNodesSelector,
  (tree) => tree.state.collapsedIds || defaults.collapsedIds,
  (
    { nodes },
    collapsedIds,
  ) => {
    const piechartSlices = [];

    let totalLeaves = 0;

    for (const nodeId of collapsedIds) {
      const node = nodes.ids[nodeId];
      if (!node.isHidden) {
        const firstIndex = node.postIndex - node.totalNodes + 1;
        const lastIndex = node.postIndex;
        const slices = new Map();
        let numberOfActiveLeaves = 0;
        for (let i = firstIndex; i <= lastIndex; i++) {
          const childNode = nodes.postorderTraversal[i];
          if (childNode.isLeaf && childNode.shape) {
            numberOfActiveLeaves++;
            slices.set(childNode.fillColour, (slices.get(childNode.fillColour) || 0) + 1);
          }
        }

        totalLeaves += numberOfActiveLeaves;
        let startAngle = 0;
        for (const [ colour, count ] of slices.entries()) {
          const ratio = count / numberOfActiveLeaves;
          const endAngle = startAngle + (Angles.Degrees360 * ratio);
          piechartSlices.push({
            node,
            colour,
            startAngle,
            endAngle,
          });
          startAngle = endAngle;
        }

        node.numberOfActiveLeaves = numberOfActiveLeaves;
      }
    }

    const k = 1 / Math.log10(totalLeaves);
    for (const nodeId of collapsedIds) {
      const node = nodes.ids[nodeId];
      if (!node.isHidden) {
        node.ratio = k * Math.log10(node.numberOfActiveLeaves);
      }
    }

    return piechartSlices;
  }
);
