import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import nodesSelector from "../../selectors/nodes";
import { EmptyArray } from "../../constants";

export default createSelector(
  nodesSelector,
  (tree) => tree.state.selectedIds ?? defaults.selectedIds,
  (
    { nodes },
    selectedIds,
  ) => {
    if ((selectedIds && selectedIds.length)) {
      const selectedNodes = [];
      for (const id of selectedIds) {
        selectedNodes.push(nodes.ids[id]);
      }
      return selectedNodes;
    }
    return EmptyArray;
  }
);
