/* eslint-disable quote-props */

export default {
  "appendToArray": require("./appendToArray").default,
  "apply-relative-layout": require("./apply-relative-layout").default,
  "applyPlugins": require("./applyPlugins").default,
  "bindMethods": require("./bindMethods").default,
  "bindSelectors": require("./bindSelectors").default,
  "calculate-node-bounds": require("./calculate-node-bounds").default,
  "calculate-scaled-centre": require("./calculate-scaled-centre").default,
  "canvasContext": require("./canvasContext").default,
  "centreToTarget": require("./centreToTarget").default,
  "clearCache": require("./clearCache").default,
  "colourToRGBA": require("./colourToRGBA").default,
  "create-controller": require("./create-controller").default,
  "createSelector": require("./createSelector").default,
  "emoji": require("./emoji").default,
  "getPostorderTraversal": require("./getPostorderTraversal").default,
  "getPreorderTraversal": require("./getPreorderTraversal").default,
  "has-interaction": require("./has-interaction").default,
  "logarithmic-scale": require("./logarithmic-scale").default,
  "measureTextWidth": require("./measureTextWidth").default,
  "parse": require("./parse").default,
  "projectValue": require("./projectValue").default,
  "rotateSubtree": require("./rotateSubtree").default,
  "scale-to-zoom": require("./scale-to-zoom").default,
  "scaleOffets": require("./scaleOffets").default,
  "targetToCentre": require("./targetToCentre").default,
  "treeTraversal": require("./treeTraversal").default,
  "treeTraversal.new": require("./treeTraversal.new").default,
  "unprojectPoint": require("./unprojectPoint").default,
  "unprojectValue": require("./unprojectValue").default,
  "zoom-to-scale": require("./zoom-to-scale").default,
};
