
import createSelector from "../utils/createSelector";
import measureTextWidth from "../utils/measureTextWidth";
import metadataBlockLengthSelector from "./metadataBlockLength";
import metadataBlockPaddingSelector from "./metadataBlockPadding";
import metadataHeaderStyleSelector from "./metadataHeaderStyle";
import metadataLabelStyleSelector from "./metadataLabelStyle";
import parsedTreeSelector from "./parsedTree";
import showMetadataHeadersSelector from "./showMetadataHeaders";
import showMetadataLabelsSelector from "./showMetadataLabels";

const metadataLabelWidthSelector = createSelector(
  parsedTreeSelector,
  (tree) => tree.state.metadata,
  (tree) => tree.state.blocks,
  metadataLabelStyleSelector,
  (
    nodes,
    metadataValues = {},
    columns = [],
    labelStyle,
  ) => {
    const columnWidths = {};

    for (const columnName of columns) {
      // find the text of the longest label
      let maxLabel = "";

      for (const nodeId of Object.keys(metadataValues)) {
        const node = nodes.ids[nodeId];
        if (node) {
          const data = metadataValues[node.id][columnName];
          if (data.label && data.label.length > maxLabel.length) {
            maxLabel = data.label;
          }
        }
      }

      // measure the actual width of the longest label if found
      if (maxLabel && maxLabel.length) {
        columnWidths[columnName] = measureTextWidth(maxLabel, labelStyle.fontFamily, labelStyle.fontSize);
      } else {
        columnWidths[columnName] = 0;
      }
    }

    return columnWidths;
  }
);
metadataLabelWidthSelector.displayName = "metadataLabelWidth";

const metadataHeaderWidthSelector = createSelector(
  (tree) => tree.state.blocks,
  metadataHeaderStyleSelector,
  (columns, headerStyle) => {
    const headerWidths = {};
    for (const columnName of columns) {
      headerWidths[columnName] = (
        2 * headerStyle.fontSize +
        measureTextWidth(columnName, headerStyle.fontFamily, headerStyle.fontSize)
      );
    }

    return headerWidths;
  }
);
metadataHeaderWidthSelector.displayName = "metadataHeaderWidth";

export default createSelector(
  (tree) => tree.state.blocks,
  metadataBlockLengthSelector,
  metadataBlockPaddingSelector,
  showMetadataHeadersSelector,
  showMetadataLabelsSelector,
  (tree) => (
    showMetadataLabelsSelector(tree) && showMetadataHeadersSelector(tree) ?
      metadataHeaderWidthSelector(tree) :
      null
  ),
  (tree) => (showMetadataLabelsSelector(tree) ? metadataLabelWidthSelector(tree) : null),
  (
    columnNames = [],
    blockLength,
    blockPadding,
    showMetadataHeaders,
    showMetadataLabels,
    headerWidths,
    labelWidths,
  ) => {
    const columnWidths = {};
    for (const columnName of columnNames) {
      let columnWidth = 0;

      if (showMetadataHeaders && showMetadataLabels) {
        columnWidth += Math.max(
          (headerWidths[columnName]),
          blockLength + (2 * blockPadding) + (labelWidths[columnName])
        );
      }
      else if (showMetadataHeaders) {
        columnWidth += blockLength + blockPadding;
      }
      else if (showMetadataLabels) {
        columnWidth += blockLength + (2 * blockPadding) + (labelWidths[columnName]);
      }
      else {
        columnWidth += blockLength + blockPadding;
      }

      columnWidths[columnName] = columnWidth;
    }

    return columnWidths;
  }
);
