export default function (tree) {
  const collapsedIds = [];

  const nodes = tree.getNodes();
  for (const node of nodes.postorderTraversal) {
    if (!node.isLeaf) {
      let allSame = true;
      let nonEmptyLabel = null;
      for (let index = 0; index < node.children.length; index++) {
        const child = node.children[index];
        if (child.label && child.label !== "") {
          if (nonEmptyLabel === null) {
            nonEmptyLabel = child.label;
          }
          else if (child.label !== nonEmptyLabel) {
            allSame = false;
            break;
          }
        }
      }

      if (nonEmptyLabel === null) {
        node.label = "";
      }
      else if (allSame) {
        node.label = nonEmptyLabel;
      }
      else {
        node.label = null;
      }
    }
  }

  for (let i = nodes.firstIndex; i < nodes.lastIndex; i++) {
    const node = nodes.preorderTraversal[i];
    if (node.label && node.label !== null) {
      collapsedIds.push(node.id);
      i += node.totalNodes - 1;
    }
  }

  tree.setState({ collapsedIds });
}
