export default function (tree, nodeOrId, { includeHidden = false } = {}) {
  const nodes = tree.getNodes();

  const subtreeNode = nodeOrId ? tree.getNodeById(nodeOrId) : nodes.root;

  const leafNodes = [];
  for (let i = subtreeNode.preIndex; i < subtreeNode.preIndex + subtreeNode.totalNodes; i++) {
    const node = nodes.preorderTraversal[i];
    if (node.isLeaf && (includeHidden || !node.isHidden)) {
      leafNodes.push(node);
    }
  }

  return leafNodes;
}
