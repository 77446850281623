import { TreeTypes, Angles, EmptyArray } from "../../constants";
import createSelector from "../../utils/createSelector";
import measureTextWidth from "../../utils/measureTextWidth";

import alignLeafLabelsSelector from "../../selectors/alignLeafLabels";
import branchScaleSelector from "../../selectors/branch-scale";
import fontSizeSelector from "../../selectors/fontSize";
import nodesSelector from "../../selectors/nodes";
import styledNodesSelector from "../../selectors/styled-nodes";
import scaleSelector from "../../selectors/scale";
import stepScaleSelector from "../../selectors/step-scale";
import treeTypeSelector from "../../selectors/treeType";
import showLeafLabelsSelector from "../../selectors/showLeafLabels";
import showInternalLabelsSelector from "../../selectors/showInternalLabels";
import nodeSizeSelector from "../../selectors/nodeSize";
import fontFamilySelector from "../../selectors/fontFamily";

function parallelDistance(a, b) {
  // This takes each pair of nodes and works out the distance between their lines
  // We assume adjacent branches are essentially parallel to one another.
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  if (a.angle === 0) return Math.abs(dy);
  if (a.angle === Math.PI / 2) return Math.abs(dx);
  const d = (dx ** 2 + dy ** 2) ** 0.5;
  return Math.abs(d * Math.sin(a.angle - Math.atan(dy / dx)));
}

const neighbourDistancesSelector = createSelector(
  nodesSelector,
  ({ nodes }) => {
    const nLeaves = nodes.leaves.length;
    return nodes.leaves.map((a, i) => {
      const b = nodes.leaves[i === 0 ? nLeaves - 1 : i - 1];
      return parallelDistance(a, b);
    });
  }
);

const pickLeavesFromRadialSelector = createSelector(
  styledNodesSelector,
  treeTypeSelector,
  alignLeafLabelsSelector,
  scaleSelector,
  fontSizeSelector,
  branchScaleSelector,
  neighbourDistancesSelector,
  (
    { nodes },
    type,
    shouldAlignLabels,
    scale,
    fontSize,
    branchScale,
    neighbourDistances
  ) => {
    const chordLength = fontSize / scale;

    // First we're going to pick some nodes which we could label
    // without their labels overlapping.
    const selectedLabels = [0];
    let circularDistance = 0;
    for (let leafIdx = 1; leafIdx < nodes.leaves.length; leafIdx++) {
      const node = nodes.leaves[leafIdx];
      circularDistance += neighbourDistances[leafIdx];
      if (circularDistance > chordLength && node.shape) {
        selectedLabels.push(leafIdx);
        circularDistance = 0;
      }
    }
    // Check that the last label isn't close to the first label
    circularDistance += neighbourDistances[0];
    if (circularDistance < chordLength) {
      selectedLabels.pop();
    }

    return selectedLabels;
  }
);

const leavesPerLabelSelector = createSelector(
  nodesSelector,
  stepScaleSelector,
  scaleSelector,
  fontSizeSelector,
  (
    { nodes },
    stepScale,
    scale,
    fontSize,
  ) => {
    const treeLength = nodes.root.totalLeaves * stepScale * scale;
    const maxNumOfLabels = treeLength / fontSize;
    const leavesPerLabel = nodes.root.totalLeaves / maxNumOfLabels;
    if (leavesPerLabel <= 1) return 1;
    // This should return the next multiple of 2
    return 2 ** Math.ceil(Math.log(leavesPerLabel) / Math.log(2));
  }
);
leavesPerLabelSelector.displayName = "leavesPerLabel";

const pickLeafLabelsFromOthogonalSelector = createSelector(
  styledNodesSelector,
  treeTypeSelector,
  alignLeafLabelsSelector,
  branchScaleSelector,
  stepScaleSelector,
  scaleSelector,
  fontSizeSelector,
  leavesPerLabelSelector,
  (
    { nodes },
    type,
    shouldAlignLabels,
    branchScale,
    stepScale,
    scale,
    fontSize,
    leavesPerLabel,
  ) => {
    if (leavesPerLabel <= 1) return nodes.leaves.map((_, i) => i);

    const lastIndex = nodes.leaves.length;
    const leavesToLabel = [];

    let index = 0;
    while (index < lastIndex) {
      const node = nodes.leaves[index];
      if (node.shape) {
        leavesToLabel.push(index);
        index = (Math.floor(index / leavesPerLabel) + 1) * leavesPerLabel;
      } else index += 1;
    }

    return leavesToLabel;
  }
);
pickLeafLabelsFromOthogonalSelector.displayName = "pickLeavesToLabel";

function pickLeavesSelector(tree) {
  const type = treeTypeSelector(tree);
  if (type === TreeTypes.Radial || type === TreeTypes.Circular) return pickLeavesFromRadialSelector(tree);
  else return pickLeafLabelsFromOthogonalSelector(tree);
}

const labelPaddingSelector = createSelector(
  nodesSelector,
  treeTypeSelector,
  alignLeafLabelsSelector,
  scaleSelector,
  fontSizeSelector,
  branchScaleSelector,
  neighbourDistancesSelector,
  pickLeavesSelector,
  (
    { nodes },
    type,
    shouldAlignLabels,
    scale,
    fontSize,
    branchScale,
    neighbourDistances,
    leavesToLabel,
  ) => {
    const chordLength = fontSize / scale;

    if (type === TreeTypes.Diagonal) {
      return leavesToLabel.map((_) => 0);
    }

    // Now that we have the labels, we need to work out how far to
    // pad them so that they don't overlap with the nodes between labels
    const labelPadding = new Array(leavesToLabel.length);
    const nLeaves = nodes.leaves.length;
    for (let selectedIdx = 0; selectedIdx < leavesToLabel.length; selectedIdx++) {
      const currentLabelIdx = leavesToLabel[selectedIdx];
      let maxDistance = nodes.leaves[currentLabelIdx].distanceFromRoot;

      if (type !== TreeTypes.Radial && shouldAlignLabels) {
        // It's much simpler if we're just aligning all the labels
        labelPadding[selectedIdx] = nodes.root.totalSubtreeLength - maxDistance;
        continue;
      }

      // Walk forewards around the tree and work out how much padding might be needed
      const nextLabelIdx = leavesToLabel[selectedIdx + 1] || nodes.leaves.length;
      for (let leafIdx = currentLabelIdx + 1; leafIdx < nextLabelIdx; leafIdx++) {
        const leafDistanceFromRoot = nodes.leaves[leafIdx].distanceFromRoot;
        if (leafDistanceFromRoot > maxDistance) maxDistance = leafDistanceFromRoot;
      }

      // Walk backwards around the tree and see how much padding might be needed
      let prevCircularDistance = neighbourDistances[currentLabelIdx];
      let leafIdx = currentLabelIdx === 0 ? nLeaves - 1 : currentLabelIdx - 1;
      while (prevCircularDistance < chordLength) {
        const leafDistanceFromRoot = nodes.leaves[leafIdx].distanceFromRoot;
        if (leafDistanceFromRoot > maxDistance) maxDistance = leafDistanceFromRoot;
        prevCircularDistance += neighbourDistances[leafIdx];
        leafIdx = leafIdx === 0 ? nLeaves - 1 : leafIdx - 1;
      }

      labelPadding[selectedIdx] = maxDistance - nodes.leaves[currentLabelIdx].distanceFromRoot;
    }

    return labelPadding;
  }
);
labelPaddingSelector.displayName = "labelPadding";

const leafLabelsSelector = createSelector(
  alignLeafLabelsSelector,
  branchScaleSelector,
  (tree) => nodeSizeSelector(tree),
  scaleSelector,
  styledNodesSelector,
  treeTypeSelector,
  labelPaddingSelector,
  pickLeavesSelector,
  (
    shouldAlignLabels,
    branchScale,
    scaledNodeSize,
    scale,
    { nodes },
    type,
    labelPadding,
    leavesToLabel,
  ) => {
    const labels = [];
    const nodesWithLabels = leavesToLabel.map((i) => nodes.leaves[i]);
    const offsetGridInterval = 32; // We'll align labels to a grid of 32 px

    for (let i = 0; i < nodesWithLabels.length; i++) {
      const node = nodesWithLabels[i];
      let padding = scaledNodeSize + labelPadding[i] * scale * branchScale;
      const inverted = (node.angle > Angles.Degrees90) && (node.angle < Angles.Degrees270);

      const x = node.x * scale;
      const y = node.y * scale;
      if (!shouldAlignLabels && type === TreeTypes.Rectangular) {
        padding = -x + (
          offsetGridInterval * Math.ceil(
            (x + padding) / offsetGridInterval
          )
        );
      }
      else if (!shouldAlignLabels && type === TreeTypes.Hierarchical) {
        padding = (Math.ceil((y + padding) / offsetGridInterval) * offsetGridInterval) - y;
      }

      labels.push({
        inverted,
        textAlign: inverted ? "right" : "left",
        transform: {
          x,
          y,
          angle: node.angle,
        },
        fillStyle: node.fillColour,
        padding: {
          x: padding,
          y: 0,
        },
        label: node.label,
        node,
      });
    }
    return labels;
  }
);
leafLabelsSelector.displayName = "leafLabels";

const estimateLabelWidthSelector = createSelector(
  scaleSelector,
  nodesSelector,
  fontSizeSelector,
  fontFamilySelector,
  (scale, { nodes }, fontSize, fontFamily) => {
    const root = nodes.root;
    const firstIndex = root.preIndex;
    const lastIndex = firstIndex + root.totalNodes;
    const internalNodes = [];
    for (let i = firstIndex; i < lastIndex; i++) {
      const node = nodes.preorderTraversal[i];
      if (node.isCollapsed) i += node.totalNodes - 1;
      if (node.isLeaf || node.isHidden) continue;
      internalNodes.push(node);
    }

    const nodesWithNames = internalNodes.filter(n => n.name && n.name !== "");
    let labelSample = "";
    for (let i = 0; i < nodesWithNames.length; i += Math.ceil(nodesWithNames.length / 128)) {
      const node = nodesWithNames[i];
      labelSample += node.name;
    }
    const totalLabelWidth = measureTextWidth(labelSample, fontFamily, fontSize);
    const averageLabelWidth = 1.5 * totalLabelWidth / labelSample.length / scale;

    let branchLengthSample = "";
    for (let i = 0; i < internalNodes.length; i += Math.ceil(internalNodes.length / 128)) {
      const node = internalNodes[i];
      branchLengthSample += String(node.branchLength);
    }
    const totalNumberWidth = measureTextWidth(branchLengthSample, fontFamily, fontSize);
    const averageNumberWidth = 1.5 * totalNumberWidth / branchLengthSample.length / scale;

    return {
      text: (label) => label.length * averageLabelWidth,
      number: (branchLength) => branchLength.length * averageNumberWidth,
    };
  }
);
estimateLabelWidthSelector.displayName = "estimateLabelWidth";

const internalLabelsSelector = createSelector(
  nodesSelector,
  (tree) => nodeSizeSelector(tree),
  scaleSelector,
  branchScaleSelector,
  estimateLabelWidthSelector,
  fontSizeSelector,
  treeTypeSelector,
  (tree) => tree.state.showInternalLabels,
  (tree) => tree.state.showBranchLengths,
  (
    { nodes },
    scaledNodeSize,
    scale,
    branchScale,
    estimateLabelWidth,
    fontSize,
    treeType,
    showInternalLabels,
    showBranchLengths,
  ) => {
    const nodeSize = scaledNodeSize / scale;
    const root = nodes.root;

    const textHeight = fontSize / scale;

    const labels = [];
    const firstIndex = root.preIndex;
    const lastIndex = firstIndex + root.totalNodes;
    for (let i = firstIndex; i < lastIndex; i++) {
      const node = nodes.preorderTraversal[i];
      if (node.isCollapsed) i += node.totalNodes - 1;

      let scaledBranchLength = node.branchLength * branchScale;
      let angle = node.angle;
      if (treeType === TreeTypes.Diagonal && node !== root) {
        const dx = node.x - node.parent.x;
        const dy = node.y - node.parent.y;
        scaledBranchLength = (dx ** 2 + dy ** 2) ** 0.5;
        angle = Math.atan(dy / dx);
      }

      const inverted = (angle > Angles.Degrees90) && (angle < Angles.Degrees270);
      const x = node.x * scale;
      const y = node.y * scale;

      // Start with internal node labels
      if (
        showInternalLabels &&
        !node.isLeaf &&
        !node.isHidden &&
        node !== root &&
        node.name !== undefined &&
        node.name !== ""
      ) {
        const labelLength = estimateLabelWidth.text(node.name);
        if (scaledBranchLength > (nodeSize + labelLength)) {
          labels.push({
            inverted,
            textAlign: inverted ? "left" : "right",
            transform: {
              x,
              y,
              angle,
            },
            fillStyle: node.fillColour,
            padding: {
              x: -scaledNodeSize / 4,
              y: (2 * scale * textHeight / 3) * (inverted ? -1 : 1),
            },
            label: node.name,
          });
        }
      }

      // Now add the branch labels
      if (showBranchLengths && !node.isHidden && node !== root) {
        let branchLabel;
        const precision8 = node.branchLength.toPrecision(8);
        const precision3 = node.branchLength.toPrecision(3);
        const exponential = Number(precision3).toExponential();
        const maxLength = scaledBranchLength;
        if (estimateLabelWidth.number(String(node.branchLength)) < maxLength) branchLabel = String(node.branchLength);
        else if (estimateLabelWidth.number(precision8) < maxLength) branchLabel = precision8;
        else if (estimateLabelWidth.number(precision3) < maxLength) branchLabel = precision3;
        else if (estimateLabelWidth.number(exponential) < maxLength) branchLabel = exponential;
        if (branchLabel !== undefined) {
          labels.push({
            inverted,
            textAlign: "center",
            transform: {
              x,
              y,
              angle,
            },
            fillStyle: node.fillColour,
            padding: {
              x: -scale * scaledBranchLength / 2,
              y: (scale * textHeight / 2) * (inverted ? 1 : -1),
            },
            label: branchLabel,
          });
        }
      }

    }
    return labels;
  }
);
internalLabelsSelector.displayName = "internalLabels";

export default createSelector(
  (tree) => (showLeafLabelsSelector(tree) ? leafLabelsSelector(tree) : EmptyArray),
  (tree) => (showInternalLabelsSelector(tree) ? internalLabelsSelector(tree) : EmptyArray),
  (leafLabels, internalLabels) => {
    if (leafLabels.length + internalLabels.length === 0) return EmptyArray;
    return [...leafLabels, ...internalLabels];
  }
);
