import renderEdges from "../render/edges";
import renderShapes from "../render/shapes";
import renderLabels from "../render/labels";
import renderMetadata from "../render/metadata";
import renderPiecharts from "../render/piecharts";
import renderSelection from "../render/selection";
import renderHighlight from "../render/highlight";
import defaults from "../defaults";
import { TreeTypes } from "../constants";

export default function (tree) {
  tree.addLayer(
    "edges",
    (state) => (state.showEdges ?? defaults.showEdges),
    renderEdges,
  );

  tree.addLayer(
    "shapes",
    (state) => (state.showShapes ?? defaults.showShapes),
    renderShapes,
  );

  tree.addLayer(
    "labels",
    (state) => state.showLabels && (state.showLeafLabels || state.showInternalLabels || state.showBranchLengths) && !tree.renderer.partial,
    renderLabels,
  );

  tree.addLayer(
    "piecharts",
    (state) => state.showPiecharts !== false && state.collapsedIds && state.collapsedIds.length,
    renderPiecharts,
  );

  tree.addLayer(
    "metadata",
    (state) => state.type !== TreeTypes.Radial && state.metadata && state.blocks && state.blocks.length,
    renderMetadata,
  );

  tree.addLayer(
    "selection",
    (state) => state.selectedIds && state.selectedIds.length,
    renderSelection,
  );

  tree.addLayer(
    "highlight",
    (state) => true, // !!(state.highlightedId ?? undefined),
    renderHighlight,
  );
}
