import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import borderColourSelector from "../shapes/border-colour";
import nodeSizeSelector from "../../selectors/nodeSize";
import slicesDataSelector from "./slices-data";
import scaleSelector from "../../selectors/scale";

import CircleSectorLayer from "./circle-sector-layer";

export default createSelector(
  slicesDataSelector,
  nodeSizeSelector,
  (tree) => tree.state.showShapeBorders,
  (tree) => tree.state.shapeBorderWidth ?? defaults.shapeBorderWidth,
  borderColourSelector,
  scaleSelector,
  (
    slicesData,
    nodeSize,
    showShapeBorders,
    shapeBorderWidth,
    borderColour,
    scale,
  ) => {
    const nodeRadius = nodeSize / 2;

    const layer = new CircleSectorLayer({
      id: "piecharts",
      data: slicesData,
      getLineColor: borderColour,
      getLineWidth: showShapeBorders ? shapeBorderWidth : 0,
      getPosition: (slice) => [ slice.node.x * scale, slice.node.y * scale ],
      getFillColor: (slice) => slice.colour,
      getCentrePoint: (edge) => [ edge.rootX, edge.rootY ],
      getRadius: (slice) => nodeRadius + (slice.node.ratio * nodeRadius),
      getStartAngle: (edge) => edge.startAngle,
      getEndAngle: (edge) => edge.endAngle,
      pickable: true,
      radiusUnits: "pixels",
    });

    return layer;
  }
);
