import defaults from "../defaults";

import createSelector from "../utils/createSelector";

import viewportCentreSelector from "./viewportCentre";
import maxZoomSelector from "./max-zoom";
import minZoomSelector from "./min-zoom";

export default createSelector(
  viewportCentreSelector,
  (tree) => tree.state.zoom ?? defaults.zoom,
  maxZoomSelector,
  minZoomSelector,
  (
    viewportCentre,
    zoom,
    maxZoom,
    minZoom,
  ) => {
    return {
      target: viewportCentre,
      zoom,
      maxZoom,
      minZoom,
    };
  }
);
