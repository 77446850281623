import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";
import colourToRGBA from "../../utils/colourToRGBA";

import lineColourSelector from "../../selectors/line-colour";
import highlightedNodeSelector from "../../selectors/highlighted-node";

export default createSelector(
  lineColourSelector,
  (tree) => tree.state.styleNodeEdges || defaults.styleNodeEdges,
  (tree) => colourToRGBA(tree.state.highlightedColour || defaults.highlightedColour),
  highlightedNodeSelector,
  (
    lineColour,
    styleNodeEdges,
    highlightedColour,
    highlightedNode,
  ) => {
    if (highlightedNode || styleNodeEdges) {
      return (edge) => {
        if (
          highlightedNode &&
          edge.preIndex > highlightedNode.preIndex &&
          edge.preIndex < highlightedNode.preIndex + highlightedNode.totalNodes
        ) {
          return highlightedColour;
        }
        else if (styleNodeEdges) {
          const colourArray = [ ...edge.strokeColour ];
          colourArray[3] = lineColour[3];
          return colourArray;
        }
        else {
          return lineColour;
        }
      };
    }
    else {
      return lineColour;
    }
  }
);
