import defaults from "../defaults";
import appendToArray from "../utils/appendToArray";

export default function (tree, nodeOrId, append = false) {
  const node = nodeOrId ? tree.getNodeById(nodeOrId) : null;

  if (node && node.isLeaf) {
    if (
      (tree.state.selectedIds && tree.state.selectedIds.length === 1 && tree.state.selectedIds[0] === node.id)
      &&
      (node.parent && node.parent.x === node.x && node.parent.y === node.y)
    ) {
      const ids = tree.getLeafIds(node.parent);
      return tree.selectLeafNodes(ids, append);
    }
    else {
      const selectedIds = appendToArray(tree.state.selectedIds, node.id, append);
      return tree.setState({ selectedIds });
    }
  }
  else if (node) {
    const ids = tree.getLeafIds(node);
    return tree.selectLeafNodes(ids, append);
  }
  else {
    return tree.setState({ selectedIds: defaults.selectedIds });
  }
}
