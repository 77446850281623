import { EmptyArray } from "../constants";

function addToSelection(currentSelection, ids, append = false) {
  if (append && currentSelection.length) {
    const newItems = new Set(currentSelection);
    for (const id of ids) {
      newItems.add(id);
    }
    return Array.from(newItems);
  }
  else if (ids.length) {
    return ids;
  }
  else {
    return EmptyArray;
  }
}

export default function (tree, ids, append) {
  if (ids && Array.isArray(ids)) {
    tree.setState({
      selectedIds: addToSelection(tree.state.selectedIds, ids, append),
    });
  }
}
