import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import strokeColourSelector from "../../selectors/strokeColour";

export default createSelector(
  strokeColourSelector,
  (tree) => tree.state.shapeBorderAlpha ?? defaults.shapeBorderAlpha,
  (
    strokeColour,
    shapeBorderAlpha,
  ) => {
    const colour = [ ...strokeColour ];

    colour[3] = shapeBorderAlpha * 255;

    return colour;
  }
);
