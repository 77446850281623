import viewportCentreSelector from "../selectors/viewportCentre";

/**
 * Translates a point on the tree plane to a point on the screen.
 *
 * @param {Object} tree - a tree instance
 * @param {Array} point - the point on the tree plane.
 *
 * @example <caption>Example usage of projectPoint with a tree transform equals { x: 10, y: 20, z: 2 }.</caption>
 * // returns { x: 10, y: 100 }
 * projectPoint(tree, 0, 40);
 *
 * @returns {Object} A point
 */
export default function projectPoint(tree, point, optionalScale) {
  const viewportTarget = viewportCentreSelector(tree);
  const scale = optionalScale ?? tree.getScale();
  const canvasCentre = tree.getCanvasCentrePoint();
  return [
    (point[0] - viewportTarget[0]) * scale + canvasCentre[0],
    (point[1] - viewportTarget[1]) * scale + canvasCentre[1],
  ];
}
