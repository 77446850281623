import { Angles, TreeTypes } from "../constants";
import createSelector from "../utils/createSelector";
import zoomToScale from "../utils/zoom-to-scale";

import drawingAreaSelector from "./drawingArea";
import parsedTreeSelector from "./parsedTree";
import treeTypeSelector from "./treeType";
import stepZoomSelector from "./step-zoom";

const stepRatioSelector = createSelector(
  parsedTreeSelector,
  treeTypeSelector,
  drawingAreaSelector,
  (nodes, treeType, area) => {
    if (treeType === TreeTypes.Rectangular || treeType === TreeTypes.Diagonal) {
      return area.height / (nodes.root.visibleLeaves);
    }

    if (treeType === TreeTypes.Hierarchical) {
      return area.width / (nodes.root.visibleLeaves);
    }

    if (treeType === TreeTypes.Circular || treeType === TreeTypes.Radial) {
      return Angles.Degrees360 / nodes.root.visibleLeaves;
    }

    throw new Error("Invalid tree type");
  }
);
stepRatioSelector.displayName = "step-ratio";

const stepScaleSelector = createSelector(
  stepRatioSelector,
  stepZoomSelector,
  (
    stepRatio,
    stepZoom,
  ) => {
    const stepScale = zoomToScale(stepZoom);
    return stepRatio * stepScale;
  },
);
stepScaleSelector.displayName = "step-scale";

export default stepScaleSelector;
