import defaults from "../defaults";

import createSelector from "../utils/createSelector";
import colourToRGBA from "../utils/colourToRGBA";

import nodeSelector from "./nodes";
import fillColourSelector from "./fillColour";
import strokeColourSelector from "./strokeColour";

const NO_STYLE = {};

export default createSelector(
  nodeSelector,
  (tree) => tree.state.styles || defaults.styles,
  (tree) => tree.state.styleNodeEdges || defaults.styleNodeEdges,
  (tree) => tree.state.showPiecharts || defaults.showPiecharts,
  (tree) => tree.state.nodeShape || defaults.nodeShape,
  fillColourSelector,
  strokeColourSelector,
  (
    { nodes },
    styles,
    styleNodeEdges,
    showPiecharts,
    defaultNodeShape,
    fillColour,
    strokeColour,
  ) => {
    {
      const firstIndex = nodes.root.preIndex;
      const lastIndex = nodes.root.preIndex + nodes.root.totalNodes;
      for (let i = firstIndex; i < lastIndex; i++) {
        const node = nodes.preorderTraversal[i];
        if (node.isLeaf) {
          const style = styles[node.id] || NO_STYLE;
          node.shape = (style.shape === undefined) ? defaultNodeShape : style.shape;
          node.fillColour = colourToRGBA(style.fillColour || fillColour);
          node.strokeColour = (styleNodeEdges && style.strokeColour) ? colourToRGBA(style.strokeColour) : strokeColour;
          node.label = (style.label === undefined) ? node.id : style.label;
        }
        else {
          node.strokeColour = strokeColour;
        }
        // skip collapsed subtrees
        if (!showPiecharts && node.isCollapsed) {
          i += node.totalNodes - 1;
        }
      }
    }

    if (styleNodeEdges) {
      const firstIndex = nodes.root.postIndex - nodes.root.totalNodes + 1;
      const lastIndex = nodes.root.postIndex;
      for (let i = firstIndex; i <= lastIndex; i++) {
        const node = nodes.postorderTraversal[i];
        if (!node.isLeaf) {
          node.strokeColour = node.children[0].strokeColour;
          for (const child of node.children) {
            if (child.strokeColour !== node.strokeColour) {
              node.strokeColour = strokeColour;
              break;
            }
          }
        }
      }
    }

    return { nodes };
  }
);
