import createSelector from "../../utils/createSelector";
import colourToRGBA from "../../utils/colourToRGBA";

import alignLeafLabelsSelector from "../../selectors/alignLeafLabels";
import branchScaleSelector from "../../selectors/branch-scale";
import metadataColumnWidthSelector from "../../selectors/metadataColumnWidth";
import styledNodesSelector from "../../selectors/styled-nodes";

export default createSelector(
  styledNodesSelector,
  alignLeafLabelsSelector,
  (tree) => tree.state.metadata,
  (tree) => tree.state.blocks,
  metadataColumnWidthSelector,
  branchScaleSelector,
  (
    { nodes },
    shouldAlignLabels,
    metadataValues,
    metadataColumns,
    columnWidths,
    branchScale,
  ) => {
    const data = [];

    for (let i = nodes.firstIndex; i < nodes.lastIndex; i++) {
      const node = nodes.preorderTraversal[i];
      if (node.isLeaf && node.shape && !node.isHidden && (node.id in metadataValues)) {

        const nodeMetadata = metadataValues[node.id];

        const nodePositionOffset = (
          shouldAlignLabels
            ?
            (branchScale * (nodes.root.totalSubtreeLength - node.distanceFromRoot))
            :
            0
        );

        const nodePosition = [
          node.x + (nodePositionOffset * Math.cos(node.angle)),
          node.y + (nodePositionOffset * Math.sin(node.angle)),
        ];

        let xOffset = 0;
        for (let index = 0; index < metadataColumns.length; index++) {
          const columnName = metadataColumns[index];
          const metadataValue = nodeMetadata[columnName];
          const columnWidth = columnWidths[columnName];

          if (metadataValue) {
            // render block
            if (metadataValue.colour) {
              // const height = blockSize + (index * stepCorrection);
              data.push({
                // inverted,
                position: nodePosition,
                offsetX: xOffset + (columnWidth / 2),
                colour: colourToRGBA(metadataValue.colour),
                columnName,
                block: metadataValue,
                node,
              });
            }
          }

          xOffset += columnWidth;
        }
      }
    }

    return data;
  }
);
