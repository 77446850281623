import treeTraversal from "./treeTraversal";

const newickParser = require("../newick");

export default function (source) {
  performance.mark("parse");

  let sourceDef = source;
  if (typeof source === "string") {
    sourceDef = { type: "newick", data: source };
  }

  const { type, data, ...options } = sourceDef;
  let rootNode = null;
  if (type === "newick" || type === undefined) {
    performance.mark("parse newick");
    rootNode = newickParser.parse_newick(data);
    performance.measure("  parse newick", "parse newick");
  } else if (type === "biojs") {
    rootNode = data;
  } else {
    throw new Error(`Source type is not supported: ${type}`);
  }

  performance.mark("treeTraversal");
  const nodes = treeTraversal(rootNode, options);
  performance.measure("  treeTraversal", "treeTraversal");
  performance.measure("parse", "parse");
  return nodes;
}
