import viewportCentreSelector from "../selectors/viewportCentre";

/**
 * Translates a point on the screen to a point on the tree plane.
 *
 * @param {Object} tree - a tree instance
 * @param {Number} pixelX - the X coordinate of the point on the screen.
 * @param {Number} pixelY - the Y coordinate of the point on the screen.
 *
 * @example <caption>Example usage of unprojectPoint with a tree transform equals { x: 10, y: 20, z: 2 }.</caption>
 * // returns { x: 0, y: 40 }
 * unprojectPoint(tree, 10, 100);
 *
 * @returns {Object} A point
 */
export default function (tree, point) {
  const viewportTarget = viewportCentreSelector(tree);
  const scale = tree.getScale();
  const canvasCentre = tree.getCanvasCentrePoint();
  return [
    viewportTarget[0] + ((point[0] - canvasCentre[0]) / scale),
    viewportTarget[1] + ((point[1] - canvasCentre[1]) / scale),
  ];
}
