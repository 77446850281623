import defaults from "../defaults";
import calculateScaledCentre from "../utils/calculate-scaled-centre";
import zoomToScale from "../utils/zoom-to-scale";

export default function (tree, branchZoom, screenPoint = tree.getCanvasCentrePoint()) {
  const axis = tree.getBranchZoomingAxis();
  if (axis) {
    const newZoom = branchZoom ?? defaults.branchZoom;
    if (screenPoint) {
      const startZoom = tree.state.branchZoom ?? defaults.branchZoom;
      const startScale = zoomToScale(startZoom);
      const newScale = zoomToScale(newZoom);
      const centre = calculateScaledCentre(tree, startScale, newScale, screenPoint, axis);
      tree.setState({
        centre,
        branchZoom: newZoom,
      });
    }
    else {
      tree.setState({ branchZoom: newZoom });
    }
  }
}
