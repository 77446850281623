import createSelector from "../utils/createSelector";

import sizeSelector from "./size";

export default createSelector(
  sizeSelector,
  (
    size,
  ) => {
    const canvasCentre = [
      size.width / 2,
      size.height / 2,
    ];
    return canvasCentre;
  }
);
