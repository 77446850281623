import defaults from "../defaults";

import createSelector from "../utils/createSelector";

import sizeSelector from "./size";
import drawingAreaSelector from "./drawingArea";

export default createSelector(
  (tree) => tree.state.centre ?? defaults.centre,
  sizeSelector,
  drawingAreaSelector,
  (
    centre,
    size,
    area,
  ) => {
    // const x = (centre[0] - 0.5) * area.width + area.left;
    // const y = (centre[1] - 0.5) * area.height + area.top;

    const canvasCentre = [
      size.width / 2,
      size.height / 2,
    ];
    const areaCentre = [
      area.width / 2 + area.left,
      area.height / 2 + area.top,
    ];
    const diff = [
      canvasCentre[0] - areaCentre[0],
      canvasCentre[1] - areaCentre[1],
    ];

    const x = (centre[0] - 0.5) * size.width - diff[0];
    const y = (centre[1] - 0.5) * size.height - diff[1];

    return [ -x, -y ];
  }
);
