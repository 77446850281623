export default function (tree, id, isVisible, renderLayer) {
  const layer = {
    id,
    isVisible,
    renderLayer,
  };

  tree.layers.push(layer);

  return layer;
}
