import createSelector from "../utils/createSelector";
import defaults from "../defaults";

import fontFamilySelector from "./fontFamily";

export default createSelector(
  (tree) => tree.state.blockHeaderFontSize || defaults.blockHeaderFontSize,
  fontFamilySelector,
  (
    fontSize,
    fontFamily,
  ) => {
    return {
      fontSize,
      fontFamily,
    };
  }
);
