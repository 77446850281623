import defaults from "../defaults";

import zoomToScale from "../utils/zoom-to-scale";

export default function (tree, zoom, screenPoint = tree.getCanvasCentrePoint()) {
  const newZoom = zoom ?? defaults.zoom;

  if (screenPoint) {
    const startScale = tree.getScale();
    const newScale = zoomToScale(newZoom);
    const scaleDelta = newScale / startScale;
    tree.deck.viewManager.controllers.OrthographicView.zoom(
      scaleDelta,
      screenPoint,
    );
  }
  else {
    tree.setView({
      zoom: newZoom,
    });
  }
}
