import { TreeTypes } from "../constants";

import createSelector from "../utils/createSelector";
import sizeSelector from "./size";
import stepScaleSelector from "./step-scale";
import treeTypeSelector from "./treeType";
import nodeSizeSelector from "./nodeSize";

export default createSelector(
  treeTypeSelector,
  sizeSelector,
  stepScaleSelector,
  nodeSizeSelector,
  (
    treeType,
    size,
    stepScale,
    nodeSize,
  ) => {
    switch (treeType) {
      case TreeTypes.Hierarchical:
        return (size.width / 2) / stepScale;

      case TreeTypes.Diagonal:
      case TreeTypes.Rectangular:
        return (size.height / 2) / stepScale;

      case TreeTypes.Radial:
      case TreeTypes.Circular:
        return Math.min(size.width, size.height) / nodeSize;

    }

    return Number.MAX_SAFE_INTEGER;
  }
);
