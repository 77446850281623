import { ScatterplotLayer } from "@deck.gl/layers";

import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";
import colourToRGBA from "../../utils/colourToRGBA";

import haloSizeSelector from "../selection/haloSize";
import highlightedNodesSelector from "./highlighted-nodes";
import nodeRadiusSelector from "../../selectors/nodeRadius";

export default createSelector(
  highlightedNodesSelector,
  (tree) => colourToRGBA(tree.state.highlightedColour || defaults.highlightedColour),
  (tree) => tree.state.haloWidth || defaults.haloWidth,
  haloSizeSelector,
  nodeRadiusSelector,
  (
    highlightedNodes,
    highlightedColour,
    haloWidth,
    haloSize,
    nodeRadius,
  ) => {
    const layer = new ScatterplotLayer({
      id: "highlight-halo",
      data: highlightedNodes,
      getPosition: (node) => [ node.x, node.y ],
      getRadius: (node) => {
        let size = haloSize.leaf;
        if (!node.isLeaf) {
          if (node.isCollapsed) {
            size = (nodeRadius + (node.ratio * nodeRadius)) + haloWidth;
          }
          else {
            size = haloSize.internal;
          }
        }
        return size;
      },
      getLineWidth: haloWidth,
      getLineColor: highlightedColour,
      filled: false,
      stroked: true,
      radiusUnits: "pixels",
      lineWidthUnits: "pixels",
    });
    return layer;
  }
);
