/* eslint-disable class-methods-use-this */

import { OrthographicController } from "@deck.gl/core";
import defaults from "../defaults";

import viewStateSelector from "../selectors/viewState";
import calculateScaledCentre from "./calculate-scaled-centre";
import scaleToZoom from "./scale-to-zoom";
import targetToCentre from "./targetToCentre";
import zoomToScale from "./zoom-to-scale";

// // Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
// let timerId;
// let throttledCall;
// const throttle = function (func, delay = 64) {
//   throttledCall = func;
//   // If setTimeout is already scheduled, no need to do anything
//   if (timerId) {
//     return;
//   }
//   // Schedule a setTimeout after delay seconds
//   timerId = setTimeout(
//     () => {
//       throttledCall();
//       // Once setTimeout function execution is finished, timerId = undefined so that in <br>
//       // the next scroll event function execution can be scheduled by the setTimeout
//       timerId = undefined;
//     },
//     delay,
//   );
// };

export default function createController(tree) {
  let timeoutId;

  class TreeController extends OrthographicController {
    // constructor(options) {
    //   super(options);
    //   this.events = [ "pointermove" ];
    // }

    getPointFromEvent(event) {
      // return [ 204, 250 ];
      const screenPoint = this.getCenter(event);
      if (!this.isPointInBounds(screenPoint, event)) {
        return false;
      }

      return screenPoint;
    }

    calculateScaleDeltaFromWheelDelta(delta) {
      const scaleDelta = 2 / (1 + Math.exp(-Math.abs(delta * 0.01)));
      if (delta < 0 && scaleDelta !== 0) {
        return 1 / scaleDelta;
      }
      else {
        return scaleDelta;
      }
    }

    _onWheel(event) {
      if (event.srcEvent.metaKey || event.srcEvent.ctrlKey) {
        event.preventDefault();
        const screenPoint = this.getPointFromEvent(event);
        if (screenPoint) {
          const scaleDelta = this.calculateScaleDeltaFromWheelDelta(event.delta);
          const zoomDelta = scaleToZoom(scaleDelta);
          const startZoom = tree.getBranchZoom();
          const newZoom = startZoom + zoomDelta;
          tree.setBranchZoom(newZoom, screenPoint);
        }
        return true;
      }
      else if (event.srcEvent.altKey || event.srcEvent.shiftKey) {
        event.preventDefault();
        const screenPoint = this.getPointFromEvent(event);
        if (screenPoint) {
          const scaleDelta = this.calculateScaleDeltaFromWheelDelta(event.delta);
          const zoomDelta = scaleToZoom(scaleDelta);
          const startZoom = tree.getStepZoom();
          const newZoom = startZoom + zoomDelta;
          tree.setStepZoom(newZoom, screenPoint);
        }
        return true;
      }
      else {
        // if (!tree.state.fixedScale) {
        //   tree.setState({ fixedScale: tree.getScale() });
        // }
        // clearTimeout(timeoutId);
        // timeoutId = setTimeout(
        //   () => tree.setState({ fixedScale: null }),
        //   266 * 10, /* 16 frames, each of which is (1000 / 60) */
        // );
        return super._onWheel(event);
      }
    }

    zoomIn(delta = 64, point = tree.getCanvasCentrePoint()) {
      const scaleDelta = this.calculateScaleDeltaFromWheelDelta(delta);
      this.zoom(scaleDelta, point);
    }

    zoomOut(delta = 64, point = tree.getCanvasCentrePoint()) {
      const scaleDelta = this.calculateScaleDeltaFromWheelDelta(-delta);
      this.zoom(scaleDelta, point);
    }

    zoom(scaleDelta, pos = tree.getCanvasCentrePoint()) {

      const { ControllerState } = this;
      this.controllerState = new ControllerState({
        makeViewport: this.makeViewport,
        ...this.controllerStateProps,
        ...this._state,
      });
      const newControllerState = this.controllerState.zoom({ pos, scale: scaleDelta });
      this.updateViewport(
        newControllerState,
        { transitionDuration: 0 },
        {
          isZooming: true,
          isPanning: true,
        },
      );
    }

  }

  return TreeController;
}
