export default function handleClick(tree, info, event) {
  // if (event.rightButton) {
  //   return;
  // }

  const node = tree.nodeFromLayer(info.layer, info.object);

  if (event.srcEvent.shiftKey && tree.state.selectedIds && tree.state.selectedIds.length) {
    const lastSelectedNode = tree.getNodeById(tree.state.selectedIds[tree.state.selectedIds.length - 1]);
    const nodes = tree.getNodes();
    const lastSelectedNodeIndex = nodes.leaves.indexOf(lastSelectedNode);
    const clickedNodeIndex = nodes.leaves.indexOf(node);
    const first = Math.min(lastSelectedNodeIndex + 1, clickedNodeIndex);
    const last = Math.max(lastSelectedNodeIndex - 1, clickedNodeIndex);
    const ids = [];
    for (let index = first; index <= last; index++) {
      ids.push(nodes.leaves[index].id);
    }
    tree.selectLeafNodes(ids, true);
  }
  else {
    tree.selectNode(node, event.srcEvent.metaKey || event.srcEvent.ctrlKey);
  }
}
