import appendToArray from "../utils/appendToArray";

export default function (tree, nodeOrId) {
  const node = tree.getNodeById(nodeOrId);
  if (node) {
    tree.setState({
      rotatedIds: appendToArray(tree.state.rotatedIds, node.id),
    });
  }
}
