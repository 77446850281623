import defaults from "../defaults";

import createSelector from "../utils/createSelector";

import nodesSelector from "./nodes";

export default createSelector(
  nodesSelector,
  (tree) => tree.state.highlightedId ?? defaults.highlightedId,
  (
    { nodes },
    highlightedId,
  ) => {
    if ((highlightedId ?? undefined) !== undefined) {
      return nodes.ids[highlightedId];
    }
    return null;
  }
);
