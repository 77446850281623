import defaults from "../defaults";

export default function (tree) {
  tree.setState({
    branchScale: defaults.branchScale,
    centre: defaults.centre,
    stepScale: defaults.stepScale,
    zoom: defaults.zoom,
  });
}
