import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";
import fontColourSelector from "./font-colour";
import fontFamilySelector from "../../selectors/fontFamily";
import fontSizeSelector from "../../selectors/fontSize";
import labelledNodesSelector from "./labelledNodes";
import lineColourSelector from "../../selectors/line-colour";
import lineWidthSelector from "../../selectors/line-width";
import scaleSelector from "../../selectors/scale";

import LabelsLayer from "./labels-layer";

export default createSelector(
  labelledNodesSelector,
  fontSizeSelector,
  fontFamilySelector,
  fontColourSelector,
  (tree) => tree.state.styleLeafLabels || defaults.styleLeafLabels,
  lineWidthSelector,
  lineColourSelector,
  scaleSelector,
  (
    labelledNodes,
    fontSize,
    fontFamily,
    fontColour,
    styleLeafLabels,
    lineWidth,
    lineColour,
    scale,
  ) => {
    const layer = new LabelsLayer({
      id: "labels",
      data: labelledNodes,
      fontSize,
      getTextPosition(datum) {
        // TODO: remove scale
        return [
          (datum.transform.x + Math.cos(datum.transform.angle) * datum.padding.x + Math.cos(datum.transform.angle) * datum.padding.y) / scale,
          (datum.transform.y + Math.sin(datum.transform.angle) * datum.padding.x + Math.sin(datum.transform.angle) * datum.padding.y) / scale,
        ];
      },
      fontColour:
        styleLeafLabels
          ?
          (datum) => (datum.node ? datum.node.fillColour : fontColour)
          :
          fontColour,
      fontFamily,
      lineWidth: lineWidth * 0.5,
      lineColour,
    });

    return layer;
  }
);
