/* eslint-disable quote-props */

export default {
  "alignLeafLabels": require("./alignLeafLabels").default,
  "baseLayout": require("./baseLayout").default,
  "branch-scale": require("./branch-scale").default,
  "branch-zoom": require("./branch-zoom").default,
  "canvasCentre": require("./canvasCentre").default,
  "canvasCentrePoint": require("./canvasCentrePoint").default,
  "descaledLineWidth": require("./descaledLineWidth").default,
  "drawingArea": require("./drawingArea").default,
  "fillColour": require("./fillColour").default,
  "fontFamily": require("./fontFamily").default,
  "fontSize": require("./fontSize").default,
  "hasMetadata": require("./hasMetadata").default,
  "highlighted-node": require("./highlighted-node").default,
  "highlightedColour": require("./highlightedColour").default,
  "line-alpha": require("./line-alpha").default,
  "line-colour": require("./line-colour").default,
  "line-width": require("./line-width").default,
  "max-scale": require("./max-scale").default,
  "max-zoom": require("./max-zoom").default,
  "maxLabelWidth": require("./maxLabelWidth").default,
  "metadataBlockLength": require("./metadataBlockLength").default,
  "metadataBlockPadding": require("./metadataBlockPadding").default,
  "metadataBlockSize": require("./metadataBlockSize").default,
  "metadataColumnWidth": require("./metadataColumnWidth").default,
  "metadataHeaderStyle": require("./metadataHeaderStyle").default,
  "metadataLabelStyle": require("./metadataLabelStyle").default,
  "metadataTotalLength": require("./metadataTotalLength").default,
  "min-scale": require("./min-scale").default,
  "min-zoom": require("./min-zoom").default,
  "nodeRadius": require("./nodeRadius").default,
  "nodeSize": require("./nodeSize").default,
  "nodes": require("./nodes").default,
  "nodesOnScreen": require("./nodesOnScreen").default,
  "offsets": require("./offsets").default,
  "padding": require("./padding").default,
  "parsedTree": require("./parsedTree").default,
  "pixel-offsets": require("./pixel-offsets").default,
  "scale": require("./scale").default,
  "selectedIds": require("./selectedIds").default,
  "showInternalLabels": require("./showInternalLabels").default,
  "showLeafLabels": require("./showLeafLabels").default,
  "showMetadataHeaders": require("./showMetadataHeaders").default,
  "showMetadataLabels": require("./showMetadataLabels").default,
  "showShapes": require("./showShapes").default,
  "size": require("./size").default,
  "step-scale": require("./step-scale").default,
  "step-zoom": require("./step-zoom").default,
  "strokeColour": require("./strokeColour").default,
  "styled-nodes": require("./styled-nodes").default,
  "treeType": require("./treeType").default,
  "viewState": require("./viewState").default,
  "viewportCentre": require("./viewportCentre").default,
  "viewportDiff": require("./viewportDiff").default,
  "world-bounds": require("./world-bounds").default,
  "zoom": require("./zoom").default,
};
