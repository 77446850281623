import { TreeTypes } from "../constants";
import createSelector from "../utils/createSelector";

import hasMetadataSelector from "./hasMetadata";
import treeTypeSelector from "./treeType";

export default createSelector(
  hasMetadataSelector,
  treeTypeSelector,
  (tree) => tree.state.showLabels,
  (tree) => tree.state.showBlockHeaders,
  (tree) => tree.state.alignLabels,
  (hasMetadata, type, showLabels, showMetadataHeaders, alignLabels) => (
    showLabels &&
      hasMetadata &&
      showMetadataHeaders &&
      (
        type === TreeTypes.Diagonal ||
        (alignLabels && type === TreeTypes.Rectangular || type === TreeTypes.Hierarchical)
      )
  )
);
