import { LineLayer } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";

import CircularCurveLayer from "./circular-curve-layer";

export default class EdgesLayer extends CompositeLayer {
  renderLayers() {
    return [
      new CircularCurveLayer({
        id: "edges-arcs",
        data: this.props.data.arcs,
        getColor: this.props.getColor,
        getWidth: this.props.getWidth,
        getCentrePoint: (edge) => [ edge.rootX, edge.rootY ],
        getRadius: (edge) => edge.dist,
        getStartAngle: (edge) => edge.fromAngle,
        getEndAngle: (edge) => edge.toAngle,
        pickable: true,
        updateTriggers: {
          getColor: this.props.getColor,
        },
      }),

      new LineLayer({
        id: "edges-lines",
        data: this.props.data.lines,
        getSourcePosition: (edge) => [ edge.x0, edge.y0 ],
        getTargetPosition: (edge) => [ edge.x1, edge.y1 ],
        getColor: this.props.getColor,
        getWidth: this.props.getWidth,
        pickable: true,
        updateTriggers: {
          getColor: this.props.getColor,
        },
      }),
    ];
  }
}
EdgesLayer.componentName = "EdgesLayer";
