import createSelector from "../utils/createSelector";

import nodesSelector from "./nodes";
import scaleSelector from "./scale";
import offsetsSelector from "./offsets";

export default createSelector(
  nodesSelector,
  scaleSelector,
  offsetsSelector,
  (
    { nodes },
    scale,
    offsets,
  ) => {
    console.time("update nodes screen x/y");
    for (let i = nodes.firstIndex; i < nodes.lastIndex; i++) {
      const node = nodes.preorderTraversal[i];
      node.sx = node.x * scale + offsets.x;
      node.sy = node.y * scale + offsets.y;
    }
    console.timeEnd("update nodes screen x/y");
    return { nodes };
  }
);
