/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-parens */

// import C2S from "canvas2svg";

import viewportCentreSelector from "../selectors/viewportCentre";
import labelledNodesSelector from "../render/labels/labelledNodes";
import shapeBorderWidthSelector from "../render/shapes/shape-border-width";
import shapeBorderColourSelector from "../render/shapes/border-colour";

import { TreeTypes } from "../constants";

function colourToRGBA(colourArray) {
  const [ r, g, b, a = 255 ] = colourArray;
  return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
}

export default function (tree) {
  const nodes = tree.getNodes();
  const size = tree.getCanvasSize();
  const type = tree.getTreeType();
  const nodeSize = tree.getNodeSize();
  const nodeRadius = nodeSize * 0.5;
  const viewportCentre = viewportCentreSelector(tree);
  const canvasCentre = tree.getCanvasCentrePoint();
  const svg = [];

  const centre = [
    canvasCentre[0] + viewportCentre[0],
    canvasCentre[1] + viewportCentre[1],
  ];

  svg.push(`<svg viewBox="0 0 ${size.width} ${size.height}" xmlns="http://www.w3.org/2000/svg">\n`);

  svg.push(`<g transform="translate(${centre.join(" ")})" >\n`);

  //#region Draw lines
  const lineWidth = tree.getLineWidth();
  const lineColour = colourToRGBA(tree.getLineColour());

  svg.push(`<g stroke="${lineColour}" stroke-width="${lineWidth}" >\n`);

  for (let i = nodes.firstIndex + 1; i < nodes.lastIndex; i++) {
    const node = nodes.preorderTraversal[i];

    if (type === TreeTypes.Circular) {
      svg.push(`<line x1="${node.x}" y1="${node.y}" x2="${node.cx}" y2="${node.cy}"  />\n`);

      // if (node.children && node.children.length && !node.isCollapsed) {
      //   const firstChild = node.children[0];
      //   const lastChild = node.children[node.children.length - 1];
      //   if (firstChild.angle < lastChild.angle) {
      //     edge.moveTo(firstChild.cx, firstChild.cy);
      //   } else {
      //     edge.moveTo(lastChild.cx, lastChild.cy);
      //   }
      //   edge.arc(
      //     nodes.root.x,
      //     nodes.root.y,
      //     node.dist,
      //     firstChild.angle,
      //     lastChild.angle,
      //     lastChild.angle < firstChild.angle
      //   );
      // }
    }
    else if (type === TreeTypes.Diagonal || type === TreeTypes.Radial) {
      svg.push(`<line x1="${node.x}" y1="${node.y}" x2="${node.parent.x}" y2="${node.parent.y}"  />\n`);
    }
    else if (type === TreeTypes.Hierarchical) {
      svg.push(`<line x1="${node.x}" y1="${node.y}" x2="${node.x}" y2="${node.parent.y}"  />\n`);
      svg.push(`<line x1="${node.x}" y1="${node.parent.y}" x2="${node.parent.x}" y2="${node.parent.y}"  />\n`);
    }
    else if (type === TreeTypes.Rectangular) {
      svg.push(`<line x1="${node.x}" y1="${node.y}" x2="${node.parent.x}" y2="${node.y}"  />\n`);
      svg.push(`<line x1="${node.parent.x}" y1="${node.y}" x2="${node.parent.x}" y2="${node.parent.y}"  />\n`);
    }

    // skip collapsed sub-trees
    if (node.isCollapsed) {
      i += node.totalNodes - 1;
    }
  }
  svg.push("</g>\n");

  //#endregion

  //#region Draw node shapes
  const showShapeBorders = tree.state.showShapeBorders;

  if (showShapeBorders) {
    const shapeBorderWidth = shapeBorderWidthSelector(tree);
    const shapeBorderColour = colourToRGBA(shapeBorderColourSelector(tree));
    svg.push(`<g stroke="${shapeBorderColour}" line-width="${shapeBorderWidth}" >\n`);
  }
  else {
    svg.push("<g>\n");
  }

  for (let i = nodes.firstIndex; i < nodes.lastIndex; i++) {
    const node = nodes.preorderTraversal[i];
    if (node.isLeaf) {
      if (!node.isHidden) {
        svg.push(`<circle cx="${node.x}" cy="${node.y}" r="${nodeRadius}" fill="${colourToRGBA(node.fillColour)}" />\n`);
      }
    }
    // skip collapsed subtrees
    if (node.isCollapsed) {
      i += node.totalNodes - 1;
    }
  }

  svg.push("</g>\n");
  //#endregion

  //#region Draw labels

  const labelData = labelledNodesSelector(tree);
  const fontFamily = tree.getFontFamily();
  const fontSize = tree.getFontSize();
  svg.push(`<g font-family="${fontFamily.replace(/"/g, "'")}" font-size="${fontSize}">\n`);

  for (const datum of labelData) {
    const x = datum.transform.x + Math.cos(datum.transform.angle) * datum.padding.x + Math.cos(datum.transform.angle) * datum.padding.y;
    const y = (datum.transform.y + Math.sin(datum.transform.angle) * datum.padding.x + Math.sin(datum.transform.angle) * datum.padding.y);
    svg.push(`<text x="${x}" y="${y}">${datum.label}</text>\n`);
  }

  svg.push("</g>\n");

  //#endregion

  svg.push("</g>\n");
  svg.push("</svg>\n");

  return new Blob(
    svg,
    { type: "image/svg+xml" },
  );
}
