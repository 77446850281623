import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";

import scaleSelector from "../../selectors/scale";

const edgeLengthThresholdSelector = createSelector(
  (tree) => Math.min(1, 1 / scaleSelector(tree)),
  (tree) => tree.state.edgeOverlapFactor || defaults.edgeOverlapFactor,
  (
    inverseScale,
    edgeOverlapFactor,
  ) => {
    // We don't want to render lines which are shorter than 1px
    // We also don't need to render separate lines if they're < 1px appart
    return Math.exp(Math.ceil(Math.log(inverseScale)) + edgeOverlapFactor);
  }
);
edgeLengthThresholdSelector.displayName = "edge-length-threshold";

export default edgeLengthThresholdSelector;
