import createSelector from "../utils/createSelector";
import logarithmicScale from "../utils/logarithmic-scale";

import scaleSelector from "./scale";
import maxScaleSelector from "./max-scale";

const minAlpha = 71; /* 0.28 x 255 */
// const minAlpha = 107 /* 0.42 x 255 */;
const maxAlpha = 255; /* 1 x 255 */
const minScale = 1;

// function linearScale(scale, maxScale) {
//   //  Scale    =>   Alpha
//   //  =======================
//   //      1    =>   0.28
//   //  scale    =>   lineAlpha
//   //  maxScale =>   1.00
// const alphaRange = maxAlpha - minAlpha;
//   const scaleRane = maxScale - minScale;
//   const lineAlpha = (scale - minScale) * alphaRange / scaleRane + minAlpha;
//   return lineAlpha;
// }

export default createSelector(
  scaleSelector,
  maxScaleSelector,
  (scale, maxScale) => {
    if (scale <= minScale) {
      return minAlpha;
    }

    else if ((scale + 0.01) >= maxScale) {
      return maxAlpha;
    }

    else {
      const alphaScale = logarithmicScale([ minScale, maxScale ], [ minAlpha, maxAlpha ]);

      const alpha = alphaScale(scale);

      const roundedAlpha = Math.floor(alpha);

      return roundedAlpha;
    }
  }
);
