import createSelector from "../../utils/createSelector";

import styledNodesSelector from "../../selectors/styled-nodes";

const visibleLeafNodesSelector = createSelector(
  styledNodesSelector,
  (
    { nodes },
  ) => {
    const visibleNodes = [];
    for (const node of nodes.leaves) {
      if (!node.isHidden && node.shape) {
        visibleNodes.push(node);
      }
    }
    return visibleNodes;
  },
);
visibleLeafNodesSelector.displayName = "visible-leaf-nodes";

export default visibleLeafNodesSelector;
