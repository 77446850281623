export default function (tree, nodeOrId = null) {
  const node = (nodeOrId !== null) ? tree.getNodeById(nodeOrId) : null;
  const highlightedId = (node && !node.isHidden) ? node.id : null;
  if ((tree.state.highlightedId ?? null) !== highlightedId) {
    tree.setState(
      { highlightedId },
      "hover",
    );
  }
}
