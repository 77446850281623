export default function (offsets, axis, scaleRatio, point) {
  let offsetX = offsets.x;
  let offsetY = offsets.y;

  if (axis === "x" || axis === "xy") {
    const offset = offsets.x;
    offsetX = offset + ((point.x) - ((point.x - offset) * scaleRatio + offset));
  }

  if (axis === "y" || axis === "xy") {
    const offset = offsets.y;
    offsetY = offset + ((point.y) - ((point.y - offset) * scaleRatio + offset));
  }

  return {
    x: offsetX,
    y: offsetY,
  };
}
