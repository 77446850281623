/* eslint-disable quote-props */

export default {
  "addLayer": require("./addLayer").default,
  "ascNodeOrder": require("./ascNodeOrder").default,
  "calculateLeafNodesCentrePoint": require("./calculateLeafNodesCentrePoint").default,
  "clearCache": require("./clearCache").default,
  "collapseNode": require("./collapseNode").default,
  "collapseSimilarNodes": require("./collapseSimilarNodes").default,
  "createLayers": require("./createLayers").default,
  "descNodeOrder": require("./descNodeOrder").default,
  "destroy": require("./destroy").default,
  "downloadSVG": require("./downloadSVG").default,
  "exportNewick": require("./exportNewick").default,
  "exportPNG": require("./exportPNG").default,
  "exportSVG": require("./exportSVG").default,
  "fitInPanel": require("./fitInPanel").default,
  "getBranchScale": require("./getBranchScale").default,
  "getBranchZoom": require("./getBranchZoom").default,
  "getBranchZoomingAxis": require("./getBranchZoomingAxis").default,
  "getCanvasCentrePoint": require("./getCanvasCentrePoint").default,
  "getCanvasSize": require("./getCanvasSize").default,
  "getDrawingArea": require("./getDrawingArea").default,
  "getFontFamily": require("./getFontFamily").default,
  "getFontSize": require("./getFontSize").default,
  "getLeafIds": require("./getLeafIds").default,
  "getLeafLabels": require("./getLeafLabels").default,
  "getLeafNodes": require("./getLeafNodes").default,
  "getLineColour": require("./getLineColour").default,
  "getLineWidth": require("./getLineWidth").default,
  "getMainAxis": require("./getMainAxis").default,
  "getMaxZoom": require("./getMaxZoom").default,
  "getMinZoom": require("./getMinZoom").default,
  "getNodeById": require("./getNodeById").default,
  "getNodeSize": require("./getNodeSize").default,
  "getNodes": require("./getNodes").default,
  "getPadding": require("./getPadding").default,
  "getScale": require("./getScale").default,
  "getStepScale": require("./getStepScale").default,
  "getStepZoom": require("./getStepZoom").default,
  "getStepZoomingAxis": require("./getStepZoomingAxis").default,
  "getTreeType": require("./getTreeType").default,
  "getWorldBounds": require("./getWorldBounds").default,
  "getZoom": require("./getZoom").default,
  "handleClick": require("./handleClick").default,
  "handleHover": require("./handleHover").default,
  "highlightNode": require("./highlightNode").default,
  "init": require("./init").default,
  "isOrthogonal": require("./isOrthogonal").default,
  "isPointOnScreen": require("./isPointOnScreen").default,
  "isWorldPointOnScreen": require("./isWorldPointOnScreen").default,
  "midpointRoot": require("./midpointRoot").default,
  "nodeAtScreen": require("./nodeAtScreen").default,
  "nodeFromLayer": require("./nodeFromLayer").default,
  "projectPoint": require("./projectPoint").default,
  "render": require("./render").default,
  "rerootNode": require("./rerootNode").default,
  "resetCollapsedNodes": require("./resetCollapsedNodes").default,
  "resetRotatedNodes": require("./resetRotatedNodes").default,
  "resize": require("./resize").default,
  "resume": require("./resume").default,
  "rotateNode": require("./rotateNode").default,
  "selectLeafNodes": require("./selectLeafNodes").default,
  "selectNode": require("./selectNode").default,
  "setBranchZoom": require("./setBranchZoom").default,
  "setFontSize": require("./setFontSize").default,
  "setNodeSize": require("./setNodeSize").default,
  "setRoot": require("./setRoot").default,
  "setScale": require("./setScale").default,
  "setSource": require("./setSource").default,
  "setState": require("./setState").default,
  "setStepZoom": require("./setStepZoom").default,
  "setStyles": require("./setStyles").default,
  "setTooltip": require("./setTooltip").default,
  "setTreeType": require("./setTreeType").default,
  "setView": require("./setView").default,
  "setZoom": require("./setZoom").default,
  "suspend": require("./suspend").default,
  "toggleAlignLeafLabels": require("./toggleAlignLeafLabels").default,
  "toggleLabels": require("./toggleLabels").default,
  "unprojectPoint": require("./unprojectPoint").default,
};
