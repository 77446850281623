import getPostorderTraversal from "../utils/getPostorderTraversal";

function getSource(newRoot) {
  const postorderTraversal = getPostorderTraversal(newRoot);
  const subtrees = [];
  for (const node of postorderTraversal) {
    if (node.isLeaf) {
      subtrees.push(`${node.id}:${node.branchLength}`);
    } else if (node !== newRoot) {
      const chunks = subtrees.splice(subtrees.length - node.children.length, node.children.length);
      subtrees.push(`(${chunks.join(",")}):${node.branchLength}`);
    }
  }

  return `(${subtrees.join(",")});`;
}

export default function (tree) {
  const nodes = tree.getNodes();
  for (const node of nodes.postorderTraversal) {
    if (node.children) {
      node.children.sort((a, b) => b.totalNodes - a.totalNodes);
    }
  }

  const source = {
    type: "newick",
    original: tree.state.source,
    data: getSource(nodes.root),
  };

  tree.setSource(source);
}
