export default function (array = [], id, append = true) {
  if (append) {
    const index = array.indexOf(id);
    if (index === -1) {
      return array.concat(id);
    }
    const nextIds = array.concat();
    nextIds.splice(index, 1);
    return nextIds;
  }
  return [ id ];
}
