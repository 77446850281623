import { TreeTypes } from "../../constants";

import createSelector from "../../utils/createSelector";

import stepScaleSelector from "../../selectors/step-scale";
import strokeColourSelector from "../../selectors/strokeColour";
import treeTypeSelector from "../../selectors/treeType";

import styledNodesSelector from "../../selectors/styled-nodes";
import scaledCanvasSizeSelector from "./scaled-canvas-size";
import edgeLengthThresholdSelector from "./edge-length-threshold";

const findEdgesSelector = createSelector(
  styledNodesSelector,
  treeTypeSelector,
  (
    { nodes },
    treeType,
  ) => {
    const edges = { vertical: [], horizontal: [], arc: [], other: [] };
    function addHorizontal(preIndex, strokeColour, y, x0, x1) {
      const edge = { preIndex, strokeColour, y, minX: x0, maxX: x1, minY: y, maxY: y, x0, x1, y0: y, y1: y };
      if (x0 > x1) {
        edge.minX = x1;
        edge.maxX = x0;
      }
      edge.length = edge.maxX - edge.minX;
      edges.horizontal.push(edge);
    }
    function addVertical(preIndex, strokeColour, x, y0, y1) {
      const edge = { preIndex, strokeColour, x, minX: x, maxX: x, minY: y0, maxY: y1, x0: x, x1: x, y0, y1 };
      if (y0 > y1) {
        edge.minY = y1;
        edge.maxY = y0;
      }
      edge.length = edge.maxY - edge.minY;
      edges.vertical.push(edge);
    }
    function addOther(preIndex, strokeColour, x0, y0, x1, y1) {
      const edge = { preIndex, strokeColour, x0, y0, x1, y1, minX: x0, minY: y0, maxX: x1, maxY: y1 };
      if (x0 > x1) {
        edge.minX = x1;
        edge.maxX = x0;
      }
      if (y0 > y1) {
        edge.minY = y1;
        edge.maxY = y0;
      }
      edge.length = edge.maxX + edge.maxY - edge.minX - edge.minY;
      edges.other.push(edge);
    }
    function addArc(preIndex, strokeColour, x0, y0, x1, y1, fromAngle, toAngle, dist) {
      const edge = {
        preIndex,
        strokeColour,
        rootX: nodes.root.x,
        rootY: nodes.root.y,
        dist,
        // fromAngle: (toAngle < fromAngle) ? fromAngle : toAngle + Angles.Degrees360,
        // toAngle: (toAngle < fromAngle) ? toAngle : fromAngle + Angles.Degrees360,
        fromAngle,
        toAngle,
        direction: toAngle < fromAngle,
        minX: x0,
        maxX: x1,
        minY: y0,
        maxY: y1,
        startX: x0,
        startY: y0,
      };
      if (x0 > x1) {
        edge.minX = x1;
        edge.maxX = x0;
      }
      if (y0 > y1) {
        edge.minY = y1;
        edge.maxY = y0;
      }
      if (fromAngle > toAngle) {
        edge.startX = x1;
        edge.startY = y1;
      }
      // Arcs are from one angle to another around a circle radius 'dist' from the root
      // Normally you can use the start and end position to work out the max and minimum x & y
      // positions of the arc.  This doesn't work if the arc spans the horizontal or vertical
      // plane.  I've split the circle into quads, 0 is bottom-right, 1 is bottom-left etc.
      const fromQuad = Math.floor(fromAngle / (0.5 * Math.PI));
      const toQuad = Math.floor(toAngle / (0.5 * Math.PI));

      if (fromQuad !== toQuad) {
        if (fromQuad >= 2 && toQuad < 2) {
          edge.maxX = nodes.root.x + dist;
        }
        if ((fromQuad === 3 || fromQuad === 0) && (toQuad === 1 || toQuad === 2)) {
          edge.maxY = nodes.root.y + dist;
        }
        if ((fromQuad <= 1) && (toQuad > 1)) {
          edge.minX = nodes.root.x - dist;
        }
        if ((fromQuad === 1 || fromQuad === 2) && (toQuad === 3 || toQuad === 0)) {
          edge.minY = nodes.root.y - dist;
        }
      }

      edge.length = Math.abs(toAngle - fromAngle) * dist;
      edges.arc.push(edge);
    }

    for (let i = nodes.firstIndex + 1; i < nodes.lastIndex; i++) {
      // We'll sort nodes into bands of overlapping nodes
      const node = nodes.preorderTraversal[i];
      const { preIndex, parent, strokeColour } = node;

      if (treeType === TreeTypes.Rectangular) {
        addHorizontal(preIndex, strokeColour, node.y, parent.x, node.x);
        addVertical(preIndex, strokeColour, parent.x, parent.y, node.y);
      }
      else if (treeType === TreeTypes.Hierarchical) {
        addHorizontal(preIndex, strokeColour, parent.y, parent.x, node.x);
        addVertical(preIndex, strokeColour, node.x, parent.y, node.y);
      }
      else if (treeType === TreeTypes.Circular) {
        addOther(preIndex, strokeColour, node.x, node.y, node.cx, node.cy);
        if (node.children && node.children.length && !node.isCollapsed) {
          const firstChild = node.children[0];
          const lastChild = node.children[node.children.length - 1];
          addArc(preIndex, strokeColour, firstChild.cx, firstChild.cy, lastChild.cx, lastChild.cy, firstChild.angle, lastChild.angle, node.dist);
        }
      }
      else if (treeType === TreeTypes.Diagonal || treeType === TreeTypes.Radial) {
        addOther(preIndex, strokeColour, node.x, node.y, node.parent.x, node.parent.y);
      }

      // skip collapsed sub-trees
      if (node.isCollapsed) {
        i += node.totalNodes - 1;
      }
    }

    edges.horizontal.sort((a, b) => a.y - b.y);
    edges.vertical.sort((a, b) => a.x - b.x);

    return edges;
  }
);
findEdgesSelector.displayName = "findEdges";

function addLinesFromBand(linesInBand, summary, axis, threshold, defaultStrokeColour) {
  if (linesInBand.length === 0) {
    return;
  }
  else if (linesInBand.length === 1) {
    summary.push(linesInBand[0]);
    return;
  }

  let from = "minX";
  let to = "maxX";
  if (axis === "vertical") {
    from = "minY";
    to = "maxY";
  }
  else if (axis !== "horizontal") throw Error("axis must be vertical or horizontal");

  linesInBand.sort((a, b) => a[from] - b[from]);

  // console.log({linesInBand})

  let prevInBand = { ...linesInBand[0] };
  for (let index = 1; index < linesInBand.length; index++) {
    const curInBand = linesInBand[index];
    // console.log(index, { minY: curInBand.minY, maxY: curInBand.maxY });
    if (curInBand[from] < (prevInBand[to] + threshold)) {
      if (prevInBand[to] < curInBand[to]) {
        prevInBand[to] = curInBand[to];
      }
      if (prevInBand.strokeColour !== curInBand.strokeColour) {
        prevInBand.strokeColour = defaultStrokeColour;
      }
      if (prevInBand.preIndex > curInBand.preIndex) {
        prevInBand.preIndex = curInBand.preIndex;
      }
    }
    else {
      prevInBand.length = prevInBand[to] - prevInBand[from];
      if (prevInBand.length > threshold) {
        prevInBand.x0 = prevInBand.minX;
        prevInBand.x1 = prevInBand.maxX;
        prevInBand.y0 = prevInBand.minY;
        prevInBand.y1 = prevInBand.maxY;
        summary.push(prevInBand);
      }
      prevInBand = { ...curInBand };
    }
  }
  prevInBand.length = prevInBand[to] - prevInBand[from];
  if (prevInBand.length > threshold) {
    prevInBand.x0 = prevInBand.minX;
    prevInBand.x1 = prevInBand.maxX;
    prevInBand.y0 = prevInBand.minY;
    prevInBand.y1 = prevInBand.maxY;
    summary.push(prevInBand);
  }
}

const findForegroundEdgesSelector = createSelector(
  findEdgesSelector,
  edgeLengthThresholdSelector,
  strokeColourSelector,
  (
    edges,
    threshold,
    defaultStrokeColour,
  ) => {
    let linesInBand;
    let bandMaximum;
    const foregroundEdges = { vertical: [], horizontal: [], other: edges.other, arc: edges.arc };

    linesInBand = [];
    bandMaximum = (edges.horizontal[0] || {}).y + threshold;
    for (const line of edges.horizontal) {
      if (line.y < bandMaximum) {
        linesInBand.push(line);
      }
      else {
        addLinesFromBand(linesInBand, foregroundEdges.horizontal, "horizontal", threshold, defaultStrokeColour);
        linesInBand = [ line ];
        bandMaximum = line.y + threshold;
      }
    }
    addLinesFromBand(linesInBand, foregroundEdges.horizontal, "horizontal", threshold, defaultStrokeColour);

    linesInBand = [];
    bandMaximum = (edges.vertical[0] || {}).x + threshold;
    for (const line of edges.vertical) {
      if (line.x < bandMaximum) {
        linesInBand.push(line);
      }
      else {
        addLinesFromBand(linesInBand, foregroundEdges.vertical, "vertical", threshold, defaultStrokeColour);
        linesInBand = [ line ];
        bandMaximum = line.x + threshold;
      }
    }
    addLinesFromBand(linesInBand, foregroundEdges.vertical, "vertical", threshold, defaultStrokeColour);

    return foregroundEdges;
  }
);
findForegroundEdgesSelector.displayName = "findForegroundEdges";

const visibleNodeCountSelector = (tree) => {
  const treeType = treeTypeSelector(tree);
  if (
    (treeType === TreeTypes.Rectangular)
  ) {
    const stepScale = stepScaleSelector(tree);
    const scaledSize = scaledCanvasSizeSelector(tree);
    const verticalNodesCount = scaledSize[1] / stepScale;
    return verticalNodesCount;
  }

  if (treeType === TreeTypes.Hierarchical || treeType === TreeTypes.Diagonal) {
    const stepScale = stepScaleSelector(tree);
    const scaledSize = scaledCanvasSizeSelector(tree);
    const horizontalNodesCount = scaledSize[0] / stepScale;
    return horizontalNodesCount;
  }

  return undefined;
};

const withoutBoundsCheckSelector = (tree) => {
  const visibleNodeCount = visibleNodeCountSelector(tree);

  if (visibleNodeCount > 2000) {
    return findForegroundEdgesSelector(tree);
  }
  else {
    return findEdgesSelector(tree);
  }
};

const edgesInTreeBounds = createSelector(
  withoutBoundsCheckSelector,
  edgeLengthThresholdSelector,
  // treeVisibleBoundsSelector,
  (
    edges,
    threshold,
    // bounds,
  ) => {
    // console.log({threshold})
    const inBounds = (edge) => {
      return (
        (edge.length > threshold)
        // &&
        // (edge.minX < bounds.maxX)
        // &&
        // (edge.maxX > bounds.minX)
        // &&
        // (edge.minY < bounds.maxY)
        // &&
        // (edge.maxY > bounds.minY)
      );
    };

    return {
      lines: [
        ...edges.horizontal.filter(inBounds),
        ...edges.vertical.filter(inBounds),
        ...edges.other.filter(inBounds),
      ],
      arcs: edges.arc.filter(inBounds),
    };
  }
);
edgesInTreeBounds.displayName = "edgesInTreeBounds";

export default edgesInTreeBounds;

// export default createSelector(
//   findEdgesSelector,
//   (
//     edges,
//   ) => {
//     return {
//       lines: [
//         ...edges.horizontal,
//         ...edges.vertical,
//         ...edges.other,
//       ],
//       arcs: edges.arc,
//     };
//   }
// );
