import createSelector from "../../utils/createSelector";
import colourToRGBA from "../../utils/colourToRGBA";

import blocksDataSelector from "./blocks-data";
import headersDataSelector from "./headers-data";
import pixelOffsetAccessorSelector from "./pixel-offset-accessor";
import metadataBlockLengthSelector from "../../selectors/metadataBlockLength";
import metadataHeaderStyleSelector from "../../selectors/metadataHeaderStyle";
import showMetadataHeadersSelector from "../../selectors/showMetadataHeaders";

import MetadataLayer from "./metadata-layer";

export default createSelector(
  blocksDataSelector,
  metadataBlockLengthSelector,
  headersDataSelector,
  metadataHeaderStyleSelector,
  pixelOffsetAccessorSelector,
  showMetadataHeadersSelector,
  (
    blockData,
    blockLength,
    headersData,
    metadataHeaderStyle,
    pixelOffsetAccessor,
    showMetadataHeaders,
  ) => {
    const layer = new MetadataLayer({
      id: "metadata",
      blocks: blockData,
      blockLength,
      headers: headersData,
      fontSize: Math.min(blockLength, metadataHeaderStyle.fontSize),
      fontColour: colourToRGBA(metadataHeaderStyle.colour || "black"),
      fontFamily: metadataHeaderStyle.fontFamily,
      getPixelOffset: pixelOffsetAccessor,
      hasHeaders: showMetadataHeaders,
    });
    return layer;
  }
);
