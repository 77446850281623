const ZeroWidthSpace = "\u200B";

export default function (tree, text) {
  if (text) {
    tree.view.title = `${text}${ZeroWidthSpace.repeat(Math.floor(Math.random() * 16))}`;
  }
  else {
    tree.view.title = "";
  }
}
