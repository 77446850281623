import minZoomSelector from "../selectors/min-zoom";

export default function (tree) {
  //  const bounds = tree._canvas.getBounds();
  // const viewRect = tree.view.getBoundingClientRect();
  // if (
  //   bounds.x < 0 ||
  //   bounds.y < 0 ||
  //   bounds.x + bounds.width > Math.min(size.width, window.innerWidth - viewRect.left) ||
  //   bounds.y + bounds.height > Math.min(size.height, window.innerHeight - viewRect.top)
  // ) {

  return minZoomSelector(tree);
}
