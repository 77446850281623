import { IconLayer } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";

// import ShapeAtlasManager from "./shape-atlas-manager";
import iconAtlas from "./icon-atlas-generator";

// import BorderedIconLayer from "./bordered-icon-layer";

export default class ShapesLayer extends CompositeLayer {
  // initializeState() {
  //   // const texture = new Texture2D(this.context.gl);
  //   this.state = {
  //     shapeAtlasManager: new ShapeAtlasManager(this.context.gl),
  //   };
  // }

  // finalizeState() {
  //   super.finalizeState();
  //   // Release resources held by the shape atlas manager
  //   this.state.shapeAtlasManager.finalize();
  // }

  renderLayers() {
    // if (!this.props.iconAtlas) {
    //   return EmptyArray;
    // }

    return [
      new IconLayer({
        id: "shapes-border",
        data: this.props.data,
        pickable: false,
        iconAtlas: iconAtlas.image,
        iconMapping: iconAtlas.mapping,
        getIcon: this.props.getIcon,
        getPosition: this.props.getPosition,
        getSize: this.props.getSize,
        sizeScale: this.props.borderSizeScale,
        getColor: this.props.borderColour,
        visible: this.props.showBorders,
        updateTriggers: {
          getSize: this.props.getSize,
        },
      }),

      new IconLayer({
        id: "shapes-fill",
        data: this.props.data,
        pickable: true,
        iconAtlas: iconAtlas.image,
        iconMapping: iconAtlas.mapping,
        getIcon: this.props.getIcon,
        getPosition: this.props.getPosition,
        getSize: this.props.getSize,
        getColor: this.props.getColor,
        updateTriggers: {
          getSize: this.props.getSize,
        },
      }),

      // new BorderedIconLayer({
      //   id: "shapes-fill",
      //   data: this.props.data,
      //   pickable: true,
      //   iconAtlas: texture,
      //   iconMapping: mapping,
      //   getIcon: this.props.getIcon,
      //   getPosition: this.props.getPosition,
      //   getSize: this.props.getSize,
      //   getColor: this.props.getColor,
      //   getBorderColor: this.props.borderColour,
      //   stroked: this.props.showBorders,
      //   updateTriggers: {
      //     getSize: this.props.getSize,
      //   },
      // }),

      // new TextLayer({
      //   id: "shapes-text",
      //   data: this.props.data,
      //   getSize: this.props.getSize,
      //   getPosition: this.props.getPosition,
      //   // getText: () => "en",
      //   // getText: () => "🇬🇧",
      //   getText: d => '\uD83D\uDC36',
      //   characterSet: ['\uD83D\uDC36', "🇬🇧"],
      //   // getColor: this.props.getColor,
      //   // fontFamily: "Segoe UI Emoji",
      //   _subLayerProps: {
      //     characters: {
      //       type: IconLayer,
      //     },
      //   },
      // }),
    ];
  }
}

ShapesLayer.defaultProps = {
  // Shared accessors
  getPosition: { type: "accessor", value: (node) => [ node.x, node.y ] },
  // iconAtlas: { type: "object", value: null, async: true },
  // iconMapping: { type: "object", value: {}, async: true },
  getIcon: { type: "accessor", value: (x) => x.shape },
  getColor: { type: "accessor", value: (x) => x.fillColour },

  // shape accessors
  getSize: { type: "accessor", value: 1 },

  // border properties
  showBorders: false,
  borderColor: [ 0, 0, 0 ],
  // borderWidth: 1,
  borderSizeScale: 1,
};

ShapesLayer.componentName = "ShapesLayer";
