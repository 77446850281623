import createSelector from "../../utils/createSelector";

import scaleSelector from "../../selectors/scale";
import sizeSelector from "../../selectors/size";

const scaledCanvasSizeSelector = createSelector(
  sizeSelector,
  scaleSelector,
  (
    size,
    scale,
  ) => {
    return [
      size.width - scale,
      size.height - scale,
    ];
  }
);
scaledCanvasSizeSelector.displayName = "scaledCanvasSize";

export default scaledCanvasSizeSelector;
