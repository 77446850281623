import createSelector from "../utils/createSelector";
import measureTextWidth from "../utils/measureTextWidth";
import parsedTreeSelector from "./parsedTree";
import fontFamilySelector from "./fontFamily";
import fontSizeSelector from "./fontSize";

const findLongestLabel = createSelector(
  parsedTreeSelector,
  (tree) => tree.state.styles,
  (nodes, styles = {}) => {
    let maxLabel = "";
    for (let i = 0; i < nodes.root.totalNodes; i++) {
      const node = nodes.preorderTraversal[nodes.root.preIndex + i];
      if (node.isLeaf && !node.isHidden) {
        const label = (node.id in styles ? styles[node.id].label : null) || node.label || node.id;
        if (label.length > maxLabel.length) {
          maxLabel = label;
        }
      }
    }
    return maxLabel;
  }
);
findLongestLabel.displayName = "findLongestLabel";

export default createSelector(
  findLongestLabel,
  fontFamilySelector,
  fontSizeSelector,
  (
    text,
    fontFamily,
    fontSize,
  ) => {
    const width = measureTextWidth(text, fontFamily, fontSize);
    return width;
  }
);
