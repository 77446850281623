import { ScatterplotLayer } from "@deck.gl/layers";

import defaults from "../../defaults";

import createSelector from "../../utils/createSelector";
import colourToRGBA from "../../utils/colourToRGBA";

import haloSizeSelector from "./haloSize";
import selectedNodesSelector from "./selected-nodes";

export default createSelector(
  selectedNodesSelector,
  (tree) => colourToRGBA(tree.state.highlightedColour || defaults.highlightedColour),
  (tree) => tree.state.haloWidth || defaults.haloWidth,
  haloSizeSelector,
  (
    selectedNodes,
    highlightedColour,
    haloWidth,
    haloSize,
  ) => {
    const layer = new ScatterplotLayer({
      id: "selection-halos",
      data: selectedNodes,
      getPosition: (node) => [ node.x, node.y ],
      getRadius: (node) => {
        let size = haloSize.leaf;
        if (!node.isLeaf) {
          if (node.isCollapsed) {
            size = node.size + haloWidth;
          }
          else {
            size = haloSize.internal;
          }
        }
        return size;
      },
      getLineWidth: haloWidth,
      getLineColor: highlightedColour,
      filled: false,
      stroked: true,
      radiusUnits: "pixels",
      lineWidthUnits: "pixels",
    });
    return layer;
  }
);
