// import getPreorderTraversal from './getPreorderTraversal';
// import getPostorderTraversal from './getPostorderTraversal';

// https://en.wikipedia.org/wiki/Tree_traversal#Post-order
function getPostorderTraversal(rootNode) {
  const nodes = [];
  const queue = [ rootNode ];

  while (queue.length) {
    const node = queue.pop();
    if (node.children) {
      for (const child of node.children) {
        child.parent = node;
        queue.push(child);
      }
      // Array.prototype.push.apply(queue, node.children);
    }
    nodes.unshift(node);
  }

  return nodes;
}

// https://en.wikipedia.org/wiki/Tree_traversal#Pre-order
function getPreorderTraversal(rootNode) {
  const nodes = [];
  const queue = [ rootNode ];

  while (queue.length) {
    const node = queue.shift();
    nodes.push(node);
    if (node.children) {
      Array.prototype.unshift.apply(queue, node.children);
    }
  }

  return nodes;
}

export default function (rootNode, { trimQuotes = true } = {}) {
  // performance.mark("getPostorderTraversal");
  const postorderTraversal = getPostorderTraversal(rootNode);
  // performance.measure("    getPostorderTraversal", "getPostorderTraversal");
  // performance.mark("getPreorderTraversal");
  const preorderTraversal = getPreorderTraversal(rootNode);
  // performance.measure("    getPreorderTraversal", "getPreorderTraversal");

  // performance.mark("top-down traversal");
  const nodeById = {};
  // Top-down traversal starting from root to leaves
  for (let nodeIndex = 0; nodeIndex < preorderTraversal.length; nodeIndex++) {
    const node = preorderTraversal[nodeIndex];
    node.preIndex = nodeIndex;
    node.isLeaf = !node.children;
    if (node.isLeaf && typeof node.name === "string") {
      if (trimQuotes) {
        node.id = node.name.trim().replace(/^['"]|['"]$/g, "");
      } else {
        node.id = node.name;
      }
      delete node.name;
    }
    if (!node.id) {
      node.id = nodeIndex.toString();
    }
    nodeById[node.id] = node;
    node.visibleLeaves = 0;
    node.isCollapsed = false;
    node.isHidden = false;
    node.totalNodes = 1;
    node.totalLeaves = node.isLeaf ? 1 : 0;
    node.totalSubtreeLength = 0;
    node.branchLength = Math.abs(node.branchLength || node.branch_length || 0);
    delete node.branch_length;
  }
  // performance.measure("    top-down traversal", "top-down traversal");

  // performance.mark("bottom-up traversal");
  // Bottom-up traversal starting from leaves to root
  for (let nodeIndex = 0; nodeIndex < postorderTraversal.length; nodeIndex++) {
    const node = postorderTraversal[nodeIndex];
    node.postIndex = nodeIndex;
    // node.isLeaf = !node.children;

    if (node.parent) {
      node.parent.totalNodes += node.totalNodes;
      node.parent.totalLeaves += node.totalLeaves;
      node.parent.visibleLeaves = node.parent.totalLeaves;
      if (node.totalSubtreeLength + node.branchLength > node.parent.totalSubtreeLength) {
        node.parent.totalSubtreeLength = node.totalSubtreeLength + node.branchLength;
      }
    }

    // node.totalNodes = 1;
    // node.totalLeaves = 1;
    // node.totalSubtreeLength = 0;
    // if (!node.isLeaf) {
    //   node.totalNodes = 1;
    //   node.totalLeaves = 0;
    //   let totalSubtreeLength = 0;
    //   for (const child of node.children) {
    //     node.totalNodes += child.totalNodes;
    //     node.totalLeaves += child.totalLeaves;
    //     if (child.totalSubtreeLength + child.branchLength > totalSubtreeLength) {
    //       totalSubtreeLength = child.totalSubtreeLength + child.branchLength;
    //     }
    //     child.parent = node;
    //   }
    //   node.totalSubtreeLength = totalSubtreeLength;
    // }
  }
  // performance.measure("    bottom-up traversal", "bottom-up traversal");

  return {
    nodeById,
    rootNode,
    postorderTraversal,
    preorderTraversal,
  };
}
