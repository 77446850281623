import ctx from "./canvasContext";

const cache = new Map();

const transparentColour = [ 0, 0, 0, 0 ];
// const transparentColour = [ 250, 250, 250 ];

export default function colourToRGBA(colour) {
  if (Array.isArray(colour)) {
    return colour;
  }

  if (colour === "transparent") {
    return transparentColour;
  }

  let rgbaArray = cache.get(colour);

  if (rgbaArray === undefined) {
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    // ctx.clearRect(0, 0, 1, 1);
    ctx.fillStyle = colour;
    ctx.fillRect(0, 0, 1, 1);
    const imageData = ctx.getImageData(0, 0, 1, 1);
    rgbaArray = imageData.data;

    // if (rgbaArray[0] === 255 && rgbaArray[1] === 255 && rgbaArray[2] === 255) {
    //   rgbaArray = transparentColour;
    // }

    // rgbaArray = [
    //   (imageData.data[0] / 255) * 245,
    //   (imageData.data[1] / 255) * 245,
    //   (imageData.data[2] / 255) * 245,
    // ];
    cache.set(colour, rgbaArray);
  }

  return rgbaArray;
}
