import { EmptyArray, TreeTypes } from "./constants";

export default Object.freeze({
  alignLabels: false,
  blockHeaderFontSize: 13,
  blockLabelFontSize: 11,
  blockLength: 16,
  blockPadding: 4,
  blocks: [],
  blockSize: null,
  branchZoom: 0,
  centre: [ 0.5, 0.5 ],
  collapsedIds: EmptyArray,
  edgeOverlapFactor: -0.5,
  fillAlpha: 1,
  fillColour: "#333333",
  fontColour: "#333333",
  fontFamily: "serif",
  fontSize: 16,
  haloRadius: 12,
  haloWidth: 4,
  highlightedColour: "#3C7383",
  interactive: false,
  keepCache: false,
  lineWidth: 1,
  metadata: {},
  nodeOverlapFactor: 0.1,
  nodeShape: "circle",
  nodeSize: 14,
  padding: 16,
  partialRender: false,
  rootId: null,
  rotatedIds: EmptyArray,
  scalebar: {
    background: [ 255, 255, 255 ],
    digits: 2,
    fillColour: [ 34, 34, 34 ],
    fontSize: 13,
    height: 24,
    lineWidth: 1,
    opacity: 0.87,
    padding: 4,
    position: {
      bottom: 0,
      left: 0,
    },
    strokeColour: [ 34, 34, 34 ],
    textAlign: "center",
    textBaseline: "hanging",
    width: 96,
  },
  selectableInternalNodes: true,
  selectableLeafNodes: true,
  selectedIds: EmptyArray,
  shapeBorderAlpha: 0.14,
  shapeBorderWidth: 1,
  showBlockHeaders: false,
  showBlockLabels: false,
  showBranchLengths: false,
  showEdges: true,
  showInternalLabels: false,
  showLabels: false,
  showLeafLabels: false,
  showPiecharts: false,
  showShapeBorders: false,
  showShapes: true,
  size: { height: 150, width: 300 }, // default canvas size
  stepZoom: 0,
  strokeColour: "#222222",
  styleLeafLabels: false,
  styleNodeEdges: false,
  styles: {},
  transform: { x: 0.5, y: 0.5, z: 1, branch: 1, step: 1 },
  treeToCanvasRatio: 0.25,
  type: TreeTypes.Rectangular,
  zoom: 0,
});
