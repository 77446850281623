import { TreeTypes } from "../constants";

import createSelector from "../utils/createSelector";

import fontSizeSelector from "./fontSize";
import hasMetadataSelector from "./hasMetadata";
import maxLabelWidthSelector from "./maxLabelWidth";
import metadataTotalLengthSelector from "./metadataTotalLength";
import nodeSizeSelector from "./nodeSize";
import showLeafLabelsSelector from "./showLeafLabels";
import showMetadataHeadersSelector from "./showMetadataHeaders";
import showShapesSelector from "./showShapes";
import treeTypeSelector from "./treeType";

const pixelOffsetsSelector = createSelector(
  treeTypeSelector,
  (tree) => (showShapesSelector(tree) ? nodeSizeSelector(tree) : 0),
  (tree) => (showLeafLabelsSelector(tree) ? maxLabelWidthSelector(tree) : 0),
  (tree) => (showMetadataHeadersSelector(tree) ? fontSizeSelector(tree) : 0),
  (tree) => (hasMetadataSelector(tree) ? metadataTotalLengthSelector(tree) : 0),
  (
    treeType,
    visibleNodeSize,
    maxLabelWidth,
    metadataHeaderFontSize,
    metadataTotalLength,
  ) => {
    let length = 0;

    length += visibleNodeSize;
    length += maxLabelWidth;

    if (metadataTotalLength > 0) {
      length += metadataTotalLength;
    }

    let preX = 0;
    let postX = 0;
    let preY = 0;
    let postY = 0;
    switch (treeType) {
      case TreeTypes.Rectangular:
      case TreeTypes.Diagonal:
        preY = Math.max(
          metadataHeaderFontSize,
          visibleNodeSize / 2,
        );
        postX = length;
        postY = visibleNodeSize / 2;
        break;
      case TreeTypes.Hierarchical:
        preX = Math.max(
          metadataHeaderFontSize,
          visibleNodeSize / 2,
        );
        postY = length;
        preY = visibleNodeSize / 2;
        break;
      case TreeTypes.Circular:
      case TreeTypes.Radial:
        preX = length;
        preY = length;
        postX = length;
        postY = length;
        break;
    }

    const left = preX;
    const top = preY;
    const right = postX;
    const bottom = postY;

    return {
      width: (postX - preX),
      height: (postY - preY),
      left,
      top,
      right,
      bottom,
    };
  }
);
pixelOffsetsSelector.displayName = "pixel-offsets";

export default pixelOffsetsSelector;
