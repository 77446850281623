import createSelector from "../utils/createSelector";

import parse from "../utils/parse";
import rotateSubtree from "../utils/rotateSubtree";

const parseSourceSelector = createSelector(
  (tree) => tree.state.source,
  (source) => {
    const { nodeById, rootNode, postorderTraversal, preorderTraversal } = parse(source);
    const nodes = {
      ids: nodeById,
      postorderTraversal,
      preorderTraversal,
      source,
      originalRoot: rootNode,
      originalSource: source.original || source,
    };
    return nodes;
  }
);
parseSourceSelector.displayName = "parseSource";

const rotateNodesSelector = createSelector(
  parseSourceSelector,
  (tree) => tree.state.rotatedIds,
  (nodes, rotatedIds) => {
    const rotatedIdsSet = new Set(rotatedIds || []);

    if (nodes.rotatedIds) {
      for (const id of nodes.rotatedIds) {
        const node = nodes.ids[id];
        if (rotatedIdsSet.has(node.id)) {
          continue;
        }
        rotateSubtree(nodes, node);
        node.isRotated = false;
      }
    }

    for (const id of rotatedIdsSet) {
      const node = nodes.ids[id];
      if (node.isRotated) {
        continue;
      }
      rotateSubtree(nodes, node);
      node.isRotated = true;
    }

    return rotatedIdsSet;
  }
);
rotateNodesSelector.displayName = "rotateNodes";

const collapseNodesSelector = createSelector(
  parseSourceSelector,
  (tree) => tree.state.collapsedIds,
  (nodes, ids) => {
    const collapsedIds = new Set(ids);

    for (let i = 1; i < nodes.postorderTraversal.length; i++) {
      const node = nodes.preorderTraversal[i];
      node.isCollapsed = collapsedIds.has(node.id);
      node.isHidden = (node.parent.isCollapsed || node.parent.isHidden);
      // if (node.parent && (node.parent.isCollapsed || node.parent.isHidden)) {
      //   node.isHidden = true;
      // }
      // else {
      //   node.isHidden = false;
      // }
    }

    for (let i = 0; i < nodes.postorderTraversal.length; i++) {
      const node = nodes.postorderTraversal[i];
      if (node.isLeaf) {
        node.visibleLeaves = 1;
      } else if (node.isCollapsed) {
        node.visibleLeaves = 1;
      } else {
        node.visibleLeaves = 0;
        for (const child of node.children) {
          node.visibleLeaves += child.visibleLeaves;
        }
      }
    }

    return collapsedIds;
  }
);
collapseNodesSelector.displayName = "collapseNodes";

export default createSelector(
  parseSourceSelector,
  rotateNodesSelector,
  collapseNodesSelector,
  (tree) => tree.state.rootId,
  (nodes, rotatedIds, collapsedIds, rootId) => {
    nodes.rotatedIds = rotatedIds;
    nodes.collapsedIds = collapsedIds;

    const root = nodes.ids[rootId] || nodes.preorderTraversal[0];
    root.distanceFromRoot = 0;
    for (let i = 1; i < root.totalNodes; i++) {
      const node = nodes.preorderTraversal[root.preIndex + i];
      node.distanceFromRoot = node.parent.distanceFromRoot + node.branchLength;
    }

    const firstIndex = root.preIndex;
    const lastIndex = root.preIndex + root.totalNodes;

    // Update leaf nodes
    const leaves = [];
    for (let i = 1; i < root.totalNodes; i++) {
      const node = nodes.preorderTraversal[root.preIndex + i];
      if (node.isLeaf) {
        leaves.push(node);
      }
      // skip collapsed subtrees
      if (node.isCollapsed) {
        i += node.totalNodes - 1;
      }
    }

    return {
      ...nodes,
      root,
      firstIndex,
      lastIndex,
      leaves,
    };
  }
);
