export default function (tree, updater, revert) {
  // const previousState = tree.previousState;
  tree.previousState = tree.state;
  if (updater) {
    tree.state = {
      ...tree.state,
      ...updater,
    };
  }

  tree.render();

  // if (revert) {
  //   tree.state = tree.previousState;
  //   tree.previousState = previousState;
  // }
}
