import createSelector from "../../utils/createSelector";

import maxLabelWidthSelector from "../../selectors/maxLabelWidth";
// import metadataBlockLengthSelector from "../../selectors/metadataBlockLength";
import nodeSizeSelector from "../../selectors/nodeSize";
import showLeafLabelsSelector from "../../selectors/showLeafLabels";
// import scaleSelector from "../../selectors/scale";

export default createSelector(
  showLeafLabelsSelector,
  (tree) => tree.state.showShapes,
  // metadataBlockLengthSelector,
  // scaleSelector,
  maxLabelWidthSelector,
  nodeSizeSelector,
  (
    hasLeafLabels,
    showShapes,
    // blockLength,
    // scale,
    maxLabelWidth,
    nodeSize,
  ) => {
    let xOffset = 0;

    // xOffset += blockLength;

    if (showShapes) {
      xOffset += nodeSize;
    }

    if (hasLeafLabels) {
      xOffset += nodeSize + maxLabelWidth;
    }

    return xOffset;
  }
);
